import CustomText from 'components/atoms/CustomText'
import Status from '@qirapagos/lib/components/atoms/Status'
import { cutTextTwentyChars, getStatusType } from '@qirapagos/lib/utils/common'
import { AvatarOutline } from 'components'
import React from 'react'
import { BoxContainer, BoxName, styles } from './styles'

interface Props {
  initials: string
  enterpriseName: string
  description: string
  status: string
}

const EnterpriseCard: React.FC<Props> = ({
  initials,
  enterpriseName,
  description,
  status
}) => {
  const statusType = getStatusType(status)
  return (
    <BoxContainer>
      <BoxName>
        <AvatarOutline initials={initials} />
        <CustomText
          size="xmedium"
          weight="regular"
          align='left'
          customStyle={styles.customName}
        >
          {cutTextTwentyChars(enterpriseName)}
        </CustomText>
      </BoxName>
      <CustomText
        size="xmedium"
        weight="mediumfont"
        customStyle={styles.customText}
      >
        {description}
      </CustomText>
      <Status statusType={statusType} customStyle={styles.statusStyle} />
    </BoxContainer>
  )
}

export default EnterpriseCard
