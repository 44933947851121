import styled from 'styled-components'
import { DEVICES } from 'theme/devicesSizes'
import { violet } from '@qirapagos/lib/theme/colors'

type TTextTitleProps = {
  hasBackArrow: boolean | undefined
}

export const ViewTopBar = styled.div`
  display:flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 40px;
  z-index: 1;
  position: relative;
  padding-bottom: 10px;
`
export const TouchableOpacityHamburger = styled.button`
  display: none;

  @media ${DEVICES.tablet} {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
  }
`

export const ViewTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media ${DEVICES.tablet} {
    margin-left: 4vw;
  }
`

export const ViewEnterpriseSelector = styled.div`
  margin-right: 20px;

  @media ${DEVICES.mobile} {
    margin-right: 0px;
  }
`

export const TextTitle = styled.p<TTextTitleProps>`
  font-size: 25px;
  color: ${violet};
  font-weight: 500;
  margin-left: ${({ hasBackArrow }) => (hasBackArrow ? '10px' : '3.7vw')};

  @media ${DEVICES.tablet} {
    margin-left: ${({ hasBackArrow }) => (hasBackArrow ? '0' : '5vw')};
    font-size: 20px;
  }

  @media ${DEVICES.mobile} {
    font-size: 19px;
  }
`
