export enum StatusType {
  INPROGRESS = 'INPROGRESS',
  NEW = 'NEW',
  ONAPROBATION = 'EN APROBACIÓN',
  APROVED = 'APROVED',
  PENDING = 'PENDING',
  CANCELED = 'CANCELED',
  COUNTEROFFERRECEIVED = 'COUNTEROFFERRECEIVED',
  NEWQUOTA = 'NEWQUOTA',
  HEFINISHED = 'HEFINISHED',
  SHEFINISHED = 'SHEFINISHED',
  REJECTED = 'RECHAZADO',
  ACCOMPLISHED = 'ACCOMPLISHED'
}
