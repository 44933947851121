import React, { ReactNode } from 'react'
import { Button, Text } from '@chakra-ui/react'
import { purple } from '@qirapagos/lib/theme/colors'

interface Props {
  onPress: () => void
  children: ReactNode
  customStyle?: any
  fontSize?: string
}

const ButtonLine: React.FC<Props> = ({ onPress, children, customStyle, fontSize }) => {
  return (
    <Button
      onClick={onPress}
      type="button"
      color={purple}
      textDecor="underline"
      bg="transparent"
      style={customStyle}
      _hover={{ bg: 'transparent' }}
      _active={{ bg: 'transparent' }}
    >
      <Text fontWeight="normal" fontSize={fontSize}>
        {children}
      </Text>
    </Button>
  )
}

export default ButtonLine

ButtonLine.defaultProps = {
  customStyle: {},
  fontSize: 'large'
}
