import { Colors } from '@qirapagos/lib/theme/index'
// Primero, agregar todos los fonts colors posibles en CustomText
// Agregar todos los background colors posibles en Colors

export enum StatusType {
  INPROGRESS = 'INPROGRESS',
  NEW = 'NEW',
  ONAPROBATION = 'ONAPROBATION',
  APROVED = 'APROVED',
  PENDING = 'PENDING',
  ENTERPRISEPENDING = 'ENTERPRISEPENDING',
  CANCELED = 'CANCELED',
  COUNTEROFFERRECEIVED = 'COUNTEROFFERRECEIVED',
  HEFINISHED = 'HEFINISHED',
  SHEFINISHED = 'SHEFINISHED',
  REJECTED = 'REJECTED',
  ACCOMPLISHED = 'ACCOMPLISHED',
  SUSPENDED = 'SUSPENDED',
}

export const STATUS_STYLES = {
  INPROGRESS: {
    text: 'EN CURSO',
    font: 'black',
    background: Colors.newPurple
  },
  NEW: {
    text: '¡NUEVO!',
    font: 'white',
    background: Colors.purple
  },
  ONAPROBATION: {
    text: 'EN APROBACIÓN',
    font: 'black',
    background: Colors.yellowSec
  },
  APROVED: {
    text: 'APROBADA',
    font: 'black',
    background: Colors.greenSec
  },
  PENDING: {
    text: 'PENDIENTE',
    font: 'disabled',
    background: Colors.grayBackLight
  },
  ENTERPRISEPENDING: {
    text: 'PENDIENTE',
    font: 'black',
    background: Colors.graySoft
  },
  CANCELED: {
    text: 'CANCELADO',
    font: 'black',
    background: Colors.redSec
  },
  COUNTEROFFERRECEIVED: {
    text: 'CONTRAOFERTA RECIBIDA',
    font: 'black',
    background: Colors.blueSec
  },
  SHEFINISHED: {
    text: 'FINALIZADA',
    font: 'black',
    background: Colors.greenSec
  },
  HEFINISHED: {
    text: 'FINALIZADO',
    font: 'black',
    background: Colors.greenSec
  },
  REJECTED: {
    text: 'RECHAZADO',
    font: 'black',
    background: Colors.redSec
  },
  ACCOMPLISHED: {
    text: 'CUMPLIDO',
    font: 'black',
    background: Colors.newPurple
  },
  SUSPENDED: {
    text: 'SUSPENDIDA',
    font: 'black',
    background: Colors.yellowSec
  }
}
export const getStatusColor = (status: string) => STATUS_STYLES[status]
