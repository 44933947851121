import React, { SetStateAction } from 'react'
import { Linking } from 'react-native'
import QiraPagosLogoROW from 'assets/svg/QiraPagosLogoROW'
import ButtonLine from '@qirapagos/lib/components/atoms/ButtonLine'
import CustomModal from '../CustomModal'
import CustomText from 'components/atoms/CustomText'
import {
  BoxButtonsContainer,
  BoxContentContainer,
  RowWrapper,
  styles
} from './styles'
import DownloadSVG from 'assets/svg/DownloadSVG'

import { getFullTermsAndConditions, getPrivacyPolicies, getShortTermsAndConditions } from 'store/auth/thunks'
import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { IconButton } from '@chakra-ui/react'
import { Colors } from 'theme'

interface Props {
  setIsOpen: SetStateAction<any>
  isOpen: boolean
}

const AboutModal: React.FC<Props> = ({ isOpen, setIsOpen }) => {
  const dispatch = useAppDispatch()
  const {
    isLoadingFullTermsAndConditions,
    isLoadingShortTermsAndConditions,
    isLoadingPrivacyPolicies
  } = useAppSelector(store => store.auth)

  const onPressDownloadFullTermsAndConditions = () => dispatch(getFullTermsAndConditions())
  const onPressDownloadShortTermsAndConditions = () => dispatch(getShortTermsAndConditions())
  const onPressDownloadPrivacyPolicies = () => dispatch(getPrivacyPolicies())

  const onPressFullTermAndCond = () => {
    Linking.openURL('https://www.qirapagos.com/terminos-y-condiciones')
  }
  const onPressShortTermAndCond = () => {
    Linking.openURL('https://www.qirapagos.com/tyc-resumido')
  }
  const onPressPrivacyPolicies = () => {
    Linking.openURL('https://www.qirapagos.com/politica-de-privacidad')
  }
  return (
    <CustomModal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      title="Acerca de Qira Pagos WEB"
    >
      <BoxContentContainer>
        <QiraPagosLogoROW />
        <CustomText
          customStyle={styles.customText}
          color="gray"
          weight="bold"
          size="big"
        >
          QIRA PAGOS WEB
        </CustomText>
        <BoxButtonsContainer>
          <RowWrapper>
            <ButtonLine
              customStyle={styles.button}
              disabled={false}
              onPress={onPressFullTermAndCond}
            >
              Términos y Condiciones
            </ButtonLine>
            <IconButton
              aria-label='Download full terms and conditions'
              onClick={onPressDownloadFullTermsAndConditions}
              isDisabled={isLoadingFullTermsAndConditions}
              isLoading={isLoadingFullTermsAndConditions}
              _loading={{ color: Colors.purple }}
              _disabled={{ cursor: 'auto' }}
              icon={<DownloadSVG />}
            />
          </RowWrapper>
          <RowWrapper>
            <ButtonLine
              customStyle={styles.button}
              disabled={false}
              onPress={onPressShortTermAndCond}
            >
              Términos y Condiciones (Resumen)
            </ButtonLine>
            <IconButton
              aria-label='Download short terms and conditions'
              onClick={onPressDownloadShortTermsAndConditions}
              isDisabled={isLoadingShortTermsAndConditions}
              isLoading={isLoadingShortTermsAndConditions}
              _loading={{ color: Colors.purple }}
              _disabled={{ cursor: 'auto' }}
              icon={<DownloadSVG />}
            />
          </RowWrapper>
          <RowWrapper>
            <ButtonLine
              customStyle={styles.button}
              disabled={false}
              onPress={onPressPrivacyPolicies}
            >
              Políticas de privacidad
            </ButtonLine>
            <IconButton
              aria-label='Download privacy policies'
              onClick={onPressDownloadPrivacyPolicies}
              isDisabled={isLoadingPrivacyPolicies}
              isLoading={isLoadingPrivacyPolicies}
              _loading={{ color: Colors.purple }}
              _disabled={{ cursor: 'auto' }}
              icon={<DownloadSVG />}
            />
          </RowWrapper>
        </BoxButtonsContainer>
      </BoxContentContainer>
    </CustomModal>
  )
}

export default AboutModal
