import React, { useCallback, useEffect, useState } from 'react'
import { Field } from 'components/molecules/Field'
import Helmet from 'components/utilities/Helmet'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { changePhone, getMyData } from 'store/auth/thunks'
import {
  setShowDrawerError,
  setShowDrawerSuccess
} from '@qirapagos/lib/store/modals/actions'
import { SCREEN_TITLES } from 'utils/navigation'
import { RenderIf, FieldSelectCountry } from 'components'
import { ErrorMessages } from '@qirapagos/lib/utils/common'
import useNavigation from 'hooks/useNavigation'
import {
  Title,
  Button,
  SubTitle,
  Container,
  FieldContainer,
  ButtonContainer
} from './styles'
import { setTwoFactorVerified } from '@qirapagos/lib/store/auth/actions'
import TwoFactorAuthentication from 'components/molecules/TwoFactorAuthentication'
import { RegPhoneArg, RegPhone } from 'constants/validations'
import { COUNTRYS } from 'constants/common'

const Steps = {
  TWO_FACTOR: 'TWO_FACTOR',
  PHONECHANGE: 'PHONECHANGE'
}

const ChangePhoneMyData = () => {
  const dispatch = useDispatch()
  const [step, setStep] = useState(Steps.PHONECHANGE)
  const [newPhone, setNewPhone] = useState('')
  const [bodyPhone, setBodyPhone] = useState({ phone: '', areaCode: '' })
  const { isLoading } = useSelector((state: any) => state.auth)
  const [countrySelectedId, setCountrySelectedId] = useState(0)

  const { control, handleSubmit, errors, clearErrors, setError, watch, setValue } =
    useForm({
      defaultValues: {
        phone: '',
        country: ''
      }
    })
  const { onNavigate } = useNavigation()

  const phoneEmpty = !watch('phone')
  const watchPhone = watch('phone')
  const countryWatch = watch('country')

  useEffect(() => {
    dispatch(getMyData())
  }, [dispatch])

  const code = COUNTRYS.find((country) => country.id === countrySelectedId)?.code

  const updatePhone = useCallback(async () => {
    try {
      await dispatch(changePhone(bodyPhone))
      dispatch(
        setShowDrawerSuccess(
          true,
          true,
          '¡Listo!',
          'Editaste tu teléfono.',
          {
            path: '/content/my-data'
          }
        )
      )
    } catch (error: any) {
      dispatch(
        setShowDrawerError(
          true,
          error?.toString() || ErrorMessages.PHONE_EDIT_ERROR,
          '',
          {
            path: '/content/my-data'
          }, 'Reintentar'
        )
      )
    }
  }, [dispatch, bodyPhone])

  const onCancel = useCallback(() => {
    onNavigate('/content/my-data', { title: 'Mis datos' })
    dispatch(setTwoFactorVerified(false))
  }, [onNavigate, dispatch])

  const onPressChange = useCallback((values) => {
    let tested: boolean

    if (countrySelectedId !== 1) {
      tested = RegPhone.test(values.phone)
    } else {
      tested = RegPhoneArg.test(values.phone)
    }

    if (tested) {
      setNewPhone(`${code?.toString()}${values.phone}`)
      setBodyPhone({ areaCode: code?.toString() || '', phone: values.phone })
      setStep(Steps.TWO_FACTOR)
    } else {
      setError('phone', { message: 'El formato del teléfono es incorrecto' })
    }
  }, [code, setError, countrySelectedId])

  useEffect(() => {
    clearErrors('country')
  }, [clearErrors, countryWatch])

  return (
    <>
      <RenderIf isTrue={step === Steps.TWO_FACTOR}>
        <TwoFactorAuthentication
          onlyBySMS
          callback={updatePhone}
          newPhone={newPhone}
        />
      </RenderIf>
      <RenderIf isTrue={step === Steps.PHONECHANGE}>
        <Container>
          <Helmet title={SCREEN_TITLES.CHANGE_EMAIL} />
          <Title>Editar teléfono</Title>
          <FieldContainer>
            <FieldSelectCountry
              data={COUNTRYS}
              control={control}
              name="country"
              label="Código país"
              placeholder="Código país"
              editable={false}
              accessibilityLabelValue="CountrySelector"
              setValue={setValue}
              setSelectedID={setCountrySelectedId}
              selected={countrySelectedId}
              error={errors.country}
              errorMessage={{
                required: 'Debe seleccionar un país'
              }}
              clearErrors={clearErrors}

            />
            <SubTitle>
              Ingresá tu número de teléfono sin el 0 y sin el 15
            </SubTitle>
            <Field
              control={control}
              name="phone"
              placeholder={code ? `+${code?.toString()}` : '3412125951'}
              error={errors.phone}
              type="text"
              clearErrors={clearErrors}
              alignError="left"

            />
          </FieldContainer>
          <ButtonContainer>
            <Button secondary onPress={onCancel} disabled={isLoading}>
              Cancelar
            </Button>
            <Button
              onPress={handleSubmit(onPressChange)}
              disabled={isLoading || phoneEmpty || watchPhone.length < 10 || watchPhone.length > 14}
              loading={isLoading}
            >
              Cambiar teléfono
            </Button>
          </ButtonContainer>
        </Container>
      </RenderIf>
    </>
  )
}

export default ChangePhoneMyData
