/* eslint-disable react/require-default-props */
import React from 'react'
import CustomText from 'components/atoms/CustomText'
import Status from '@qirapagos/lib/components/atoms/Status'
import { StatusType } from '@qirapagos/lib/components/atoms/Status/constants'
import { ViewStageBox, styles, ViewStatus } from './styles'

interface Props {
  title?: string
  text?: string
  statusType: StatusType | string
  statusFontSize?: string | number
  isSelected?: boolean
}

const StageBox: React.FC<Props> = ({
  title,
  text,
  statusType,
  statusFontSize,
  isSelected
}) => {
  return (
    <ViewStageBox
      isPending={statusType === 'PENDING'}
      isDone={statusType === 'SHEFINISHED'}
      isInProgress={statusType === 'INPROGRESS'}
      isSelected={isSelected}
    >
      <CustomText customStyle={statusType === 'PENDING' ? styles.titleDisabled : styles.title}>
        {title}
      </CustomText>
      <CustomText customStyle={statusType === 'PENDING' ? styles.textDisabled : styles.text}>
        {text}
      </CustomText>
      <ViewStatus>
        <Status
          statusType={statusType}
          fontSize={statusFontSize}
        />
      </ViewStatus>
    </ViewStageBox>
  )
}

export default StageBox
