import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
  button: {
    width: '15%',
    minWidth: 100,
    alignSelf: 'flex-end',
    marginRight: 30
  }
})
