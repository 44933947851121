import React, { useEffect, useRef, useState } from 'react'
import { Field } from 'components/molecules/Field'
import { white } from '@qirapagos/lib/theme/colors'
import { Box } from '@chakra-ui/react'
import Button from '@qirapagos/lib/components/atoms/Button/index.web'
import { useAppSelector } from 'hooks/redux'
import { useDispatch } from 'react-redux'
import { getAccountInfo, validateAccount } from 'store/transfers/thunks'
import { styles } from './styles'
import { TSetState } from 'types/generics'
import useNavigation from 'hooks/useNavigation'

interface Props {
  setIndex: TSetState<number>
  titlesInfo: any
  setError: any
  getValues: any
  errors: any
  control: any
}

export const StepOne: React.FC <Props> = ({
  setIndex,
  titlesInfo,
  setError,
  getValues,
  errors,
  control
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const { account } = useAppSelector(
    state => state.transfers
  )

  const { location } = useNavigation()
  const { dataAddressee } = location.state as any
  const dispatch = useDispatch()

  const check = async () => {
    const response: any = await dispatch(validateAccount(dataAddressee, setError))
    if (response.data !== undefined) {
      titlesInfo.setDataSecondStep(response.data)
      titlesInfo.setComplete(true)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    dispatch(getAccountInfo())
  }, [])

  useEffect(() => {
    if (dataAddressee) {
      check()
    }
  }, [dataAddressee])

  const onPress = async (cuit: string) => {
    setIsLoading(true)
    const email = (await localStorage.getItem('email')) || ''
    type TCompare = {
      [key: string]: string
    }
    const toCompare: TCompare = {
      email,
      cvu: account.cvu,
      alias: account.alias
    }

    if (cuit.length === 0) {
      setError('cuit', {
        message: 'Debe ingresar un Alias / CVU / CBU / Email'
      })
      setIsLoading(false)
      return
    }

    if (
      Object.keys(toCompare).some(
        key => toCompare[key].toLowerCase() === cuit.toLowerCase()
      )
    ) {
      setError('cuit', {
        message: 'No se pueden realizar transferencias a usted mismo.'
      })
      setIsLoading(false)
    } else {
      const response: any = await dispatch(validateAccount(cuit, setError))
      if (!response?.data) {
        setError('cuit', { message: 'No se encuentra la cuenta ingresada' })
      } else {
        titlesInfo.setDataSecondStep(response.data)
        setIndex(1)
        titlesInfo.setComplete(true)
      }
      setIsLoading(false)
    }
  }

  const inputFocusRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    setTimeout(() => {
      if (inputFocusRef.current) {
        inputFocusRef.current.focus()
      }
    }, 0)
  }, [])

  return (
    <Box bg={white} display="flex" flexDirection="column">
      <Box mt={50} mb={50} w="30%" alignSelf="center" minWidth="230px">
        <Field
          control={control}
          name="cuit"
          placeholder="Alias / CBU / CVU / Email"
          error={errors.cuit}
          ref={inputFocusRef}
        />
      </Box>
      <Button
        onPress={() => onPress(getValues('cuit'))}
        customStyle={styles.button}
        loading={isLoading}
        testID='continueAlias'
      >
        Continuar
      </Button>
    </Box>
  )
}

export default StepOne
