import { StyleSheet } from 'react-native'
import { Colors, Spacing } from '../../../theme/index'
import { WINDOW_WIDTH } from '../../../constants/platform'

const HALF = WINDOW_WIDTH / 2
const BUTTON_WIDTH = HALF - Spacing.LATERAL - 5.5

export default StyleSheet.create({
  button: {
    width: '100%',
    backgroundColor: Colors.purple,
    borderRadius: 8,
    paddingVertical: Spacing.SMALL,
    marginBottom: Spacing.BIG,
    alignSelf: 'center',
    height: 59
  },
  disabled: {
    backgroundColor: Colors.purpleDisabled
  },
  disabledSecondary: {
    opacity: 0.5
  },
  home: {
    width: BUTTON_WIDTH,
    paddingVertical: 11
  },
  fixedBottom: {
    position: 'absolute',
    bottom: 0
  },
  secondary: {
    backgroundColor: '#f0edff'
  },
  terciary: {
    backgroundColor: '#FFFFFF'
  }
})
