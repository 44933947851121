import React, { useEffect } from 'react'
import { MyAccountDetails, Table } from 'components'
import { useDispatch } from 'react-redux'
import { getAccountInfo, getTransfers } from 'store/transfers/thunks'
import { configTransfers } from '../constants'
import AccountDetailsSkeleton from 'assets/skeletons/AccountDetailsSkeleton'
import { useTable } from 'hooks/useTable'
import { FilterType } from 'constants/filters'
import { setShowDrawerStatement } from '@qirapagos/lib/store/modals/actions'
import DownloadSVG from 'assets/svg/DownloadSVG'
import Helmet from 'components/utilities/Helmet'
import { SCREEN_TITLES } from 'utils/navigation'
import { useAppSelector } from 'hooks/redux'
import { readLastLogin } from 'store/auth/thunks'

const AccountDetails: React.FC = () => {
  const { isLoading, movements, account } = useAppSelector(
    state => state.transfers
  )
  const { handleFilterType, handleFilterMovements, handleDateFilterType } =
    useTable()
  const dispatch = useDispatch()

  const handleOpenDrawer = () => {
    dispatch(setShowDrawerStatement(true))
  }

  useEffect(() => {
    if (!account.alias) dispatch(getAccountInfo())
    if (!movements) dispatch(getTransfers(10))
    dispatch(readLastLogin())
    handleFilterType(FilterType.ALL_MOVEMENTS)
    handleDateFilterType(FilterType.ALL_DATES)
  }, [dispatch])

  const action = {
    title: '',
    callback: () => null
  }

  if (isLoading) {
    return <AccountDetailsSkeleton />
  }

  return (
    <>
      <Helmet title={SCREEN_TITLES.MY_ACCOUNT_DETAILS} />
      <MyAccountDetails action={action} />
      <Table
        onClick={handleOpenDrawer}
        icon={<DownloadSVG />}
        data={handleFilterMovements(movements) || []}
        config={configTransfers}
        title="Movimientos"
        fromScreen='yourAccount'
        filterOptions={[
          FilterType.ALL_MOVEMENTS,
          FilterType.INCOMING,
          FilterType.OUTGOING
        ]}
        onFilter={handleFilterType}
        emptyText="Aún no tenés ningún movimiento"
      />
    </>
  )
}

export default AccountDetails

AccountDetails.defaultProps = {
  navigation: {}
}
