import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import Helmet from 'components/utilities/Helmet'
import ButtonLine from '@qirapagos/lib/components/atoms/ButtonLine'
import CustomText from 'components/atoms/CustomText'
import { AuthLayout, Form, NotValidUserModal } from 'components'
import { loginUser, logOut } from 'store/auth/thunks'
import useNavigation from 'hooks/useNavigation'
import { Field as FieldWeb } from 'components/molecules/Field'
import QiraPagosLogoROW from 'assets/svg/QiraPagosLogoROW'
import Button from 'components/atoms/Button'
import { Flex, Slide } from '@chakra-ui/react'
import { slideStyle } from './styles'
import { ViewLogoContainer } from 'theme/globalStyles'
import { useScreenSizes } from 'hooks/useScreenSizes'

type FormData = {
  email: string
  password: string
}

const Login: React.FC = () => {
  const { isLoading } = useSelector((state: any) => state.auth)
  const { showModal } = useSelector((state: any) => state.modal)
  const { control, clearErrors, errors, watch, setError, handleSubmit } =
    useForm<FormData>({
      defaultValues: { email: '', password: '' },
      mode: 'onSubmit',
      reValidateMode: 'onSubmit'
    })

  const { isTabletDisplayOrLower, isMobileDisplay } = useScreenSizes()
  const dispatch = useDispatch()
  const { onNavigate } = useNavigation()

  const emailValue = watch('email')
  const passwordValue = watch('password')

  const disabled = !emailValue || !passwordValue

  useEffect(() => {
    dispatch(logOut(onNavigate))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setSubmitError = (error: string) => {
    setError('email', { message: error })
    setError('password', { message: error })
  }

  const onSubmit = (data: FormData) => {
    dispatch(
      loginUser(
        { user: data.email, password: data.password },
        onNavigate,
        setSubmitError
      )
    )
  }

  return (
    <AuthLayout>
      {isTabletDisplayOrLower && !isMobileDisplay && (
        <ViewLogoContainer>
          <Slide in direction="left" style={slideStyle}>
            <QiraPagosLogoROW />
          </Slide>
        </ViewLogoContainer>
      )}
      <Helmet title="Iniciar sesión" />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Flex
          mt="20px"
          marginTop="50px"
          w="180%"
          maxW="360px"
          direction="column"
          alignSelf="center"
          justifyContent="space-around"
        >
          <CustomText
            size="big"
            weight="mediumfont"
            customStyle={{ marginBottom: 50 }}
          >
            Iniciar Sesión
          </CustomText>
          <FieldWeb
            control={control}
            name="email"
            placeholder="Email"
            error={errors.email}
            showErrorMessage={false}
            clearErrors={clearErrors}
            type="email"
          />

          <FieldWeb
            control={control}
            name="password"
            placeholder="Contraseña"
            error={errors.password}
            clearErrors={clearErrors}
            type="password"
          />

          <Flex direction="column" alignItems="center" mt="120px">
            <ButtonLine
              onPress={() => onNavigate('/auth/forgot-password')}
              marginBottom={20}
            >
              Olvidé mi contraseña
            </ButtonLine>
            <Button
              disabled={disabled}
              loading={isLoading}
              onPress={handleSubmit(onSubmit)}
            >
              Ingresar
            </Button>
          </Flex>
        </Flex>
      </Form>
      <NotValidUserModal isOpen={showModal === 'WEBinvalidUser'} />
    </AuthLayout>
  )
}

export default Login
