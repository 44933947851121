/* eslint-disable react/require-default-props */
import React, { useCallback, useState } from 'react'
import { formatDecimalsHelper } from '@qirapagos/lib/utils/common'
import { LocalStorage } from 'constants/localStorage'
import EyeShowPassword from '@qirapagos/lib/assets/svg/EyeShowPassword'
import EyeSlashPassword from '@qirapagos/lib/assets/svg/EyeSlashPassword'
import { moneySymbol } from '@qirapagos/lib/constants/common'
import ButtonLine from '@qirapagos/lib/components/atoms/ButtonLine'
import { useAppSelector } from 'hooks/redux'
import {
  Container,
  Title,
  Subtitle,
  AmountContainer,
  BalanceWithNoDecimals,
  BalanceDecimals,
  EyeButton
} from './style'

interface Props {
  title: string
  subtitle: string
  buttonText: string
  onPressButton: () => void
}

const Balance: React.FC<Props> = ({
  title,
  subtitle,
  buttonText,
  onPressButton
}) => {
  const { balance } = useAppSelector((state) => state.transfers)
  const [showPassword, setShowPassword] = useState(localStorage.getItem(LocalStorage.SHOW_PASSWORD) === 'true')
  const { balanceWithNoDecimals, decimals } = formatDecimalsHelper(balance)

  const showAmount = useCallback(() => {
    setShowPassword(!showPassword)
    localStorage.setItem(LocalStorage.SHOW_PASSWORD, String(!showPassword))
  }, [setShowPassword, showPassword])

  const Amount = (
    <>
      <BalanceWithNoDecimals>
        {moneySymbol}
        {balanceWithNoDecimals}
      </BalanceWithNoDecimals>
      <BalanceDecimals>{decimals}</BalanceDecimals>
    </>
  )

  return (
    <Container>
      <Title>
        {title}
      </Title>
      <Subtitle>
        {subtitle}
      </Subtitle>
      <AmountContainer>
        {showPassword
          ? Amount
          : (<BalanceWithNoDecimals>**********</BalanceWithNoDecimals>)}
        <EyeButton onClick={showAmount}>
          {showPassword ? <EyeShowPassword /> : <EyeSlashPassword />}
        </EyeButton>
      </AmountContainer>
      <ButtonLine onPress={onPressButton}>
        {buttonText}
      </ButtonLine>
    </Container>
  )
}

export default Balance
