import { api } from '@qirapagos/lib/config/api'
import { insertKeyEndpoint } from './utils'

const REDEEMERS = 'redeemingCompanies'
const GET_AGENT_BY_CUIT = 'company/cuit'
const NEW_AGENT = 'representation'
const GET_AGENTS = 'representation/company'
const DELETE_AGENT = 'representation/deactive'

export const getRedeemers = (
  limit: number,
  page: number,
  token: string,
  keyEndpoints: string
) =>
  api.get(
    insertKeyEndpoint(`${REDEEMERS}?limit=${limit}&page=${page}`, keyEndpoints),
    {},
    { headers: { token } }
  )

export const getAgentByCuit = (
  cuit: string,
  token: string | null,
  keyEndpoints: string
) =>
  api.get(
    insertKeyEndpoint(`${GET_AGENT_BY_CUIT}/${cuit}`, keyEndpoints),
    {},
    { headers: { token } }
  )

export const newAgent = (body: object, token: string | null, keyEndpoints: string) =>
  api.post(insertKeyEndpoint(NEW_AGENT, keyEndpoints), body, {
    headers: { token }
  })

export const getAgents = (
  token: string | null,
  companyId: string | null,
  keyEndpoints: string) =>
  api.get(
    insertKeyEndpoint(`${GET_AGENTS}/${companyId}`, keyEndpoints),
    {},
    { headers: { token } }
  )

export const deleteAgent = (id: number, token: string | null, keyEndpoints: string) =>
  api.put(
    insertKeyEndpoint(`${DELETE_AGENT}/${id}`, keyEndpoints),
    {},
    { headers: { token } }
  )
