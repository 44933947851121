import React from 'react'
import { BoxStatus } from './styles'
import { StatusType, getStatusColor } from './constants'
import CustomText from '../CustomText'

interface Props {
  statusType: StatusType;
}

const Status: React.FC<Props> = ({ statusType }) => {
  const { text, font, background } = getStatusColor(statusType)

  return (
    <BoxStatus background={background}>
      <CustomText size="small" weight="semibold" color={font}>
        {text}
      </CustomText>
    </BoxStatus>
  )
}

export default Status
