import styled from 'styled-components'
import { DEVICES } from 'theme/devicesSizes'

export const Container = styled.div`
  display: flex;
  align-content: flex-start;
  border-radius: 6px;
  flex-wrap: wrap;
  margin-bottom: 20px;
  margin-top: 30px;
  height: 80vh;
  margin-left: 50px;
  overflow: scroll;

  @media ${DEVICES.tablet} {
    justify-content: center;
    margin-left: 20px;
  }
`
