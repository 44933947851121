/* eslint-disable react/require-default-props */
import React from 'react'
import { Spinner } from '@chakra-ui/react'
import { ButtonText, ButtonWrapper } from './styles'

interface Props {
  onPress?: (param?: any) => void;
  children: React.ReactNode
  disabled?: boolean
  loading?: boolean
  customStyle?: React.CSSProperties
  fixed?: boolean
  secondary?: boolean
  terciary?: boolean
  fromHome?: boolean
  id?: string
  className?: string
  accessibilityLabelValue?: string
}

const Button: React.FC<Props> = ({
  onPress,
  children,
  disabled,
  loading,
  customStyle,
  fixed,
  secondary,
  terciary,
  fromHome,
  className,
  id,
  accessibilityLabelValue
}) => {
  const onPressHandler = () => {
    if (!disabled && onPress) onPress()
  }

  return (
    <ButtonWrapper
      className={className}
      style={customStyle}
      type="submit"
      fromHome={fromHome}
      fixed={fixed}
      secondary={secondary}
      tertiary={terciary}
      disabled={loading || disabled}
      onClick={onPressHandler}
      id={id}
      aria-label={accessibilityLabelValue}
    >
      <ButtonText
        secondary={secondary}
      >
        {loading ? <Spinner width={30} height={30} data-testid='spinner' /> : children}
      </ButtonText>
    </ButtonWrapper>
  )
}

export default Button
