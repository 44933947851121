import React from 'react'
import { Box, Text } from '@chakra-ui/react'
import { gray, purple } from '@qirapagos/lib/theme/colors'
import Button from '@qirapagos/lib/components/atoms/Button/index.web'
import useNavigation from 'hooks/useNavigation'
import PencilEdit from 'assets/svg/PencilEdit'
import { TouchableOpacity } from 'react-native'
import { styles } from './styles'
import { TSetState } from 'types/generics'
import { addCommaZero } from 'utils/common'
import { useDispatch } from 'react-redux'
import { setTransferAccount } from '@qirapagos/lib/store/transfers/actions'

interface Props {
  setIndex: TSetState<number>
  titlesInfo: any
}

const StepFour: React.FC<Props> = ({ setIndex, titlesInfo }) => {
  const amountToLocaleString = Number(titlesInfo[3]?.dataAmount)?.toLocaleString('es-AR')
  const { onNavigate } = useNavigation()
  const dispatch = useDispatch()
  const onPress = () => {
    dispatch(setTransferAccount({ amount: Number(titlesInfo[3]?.dataAmount) }))
    setIndex(4)
    titlesInfo[3].setComplete(true)
  }

  const onPressCancel = () => {
    setIndex(0)
    onNavigate('/content/your-account/new-transfer', {
      title: 'Nueva transferencia',
      hasBackArrow: true
    })
  }

  const onPressEditAmount = () => {
    setIndex(2)
    titlesInfo[2].setComplete(false)
  }

  return (
    <Box display="flex" flexDir="column">
      <Box display="flex" justifyContent="space-around" margin="50px 0px">
        <Box
          display="flex"
          flexDir="column"
          alignItems="center"
          justifyContent="space-around"
        >
          <Text
            flex={1}
            color={purple}
            fontSize={{ sm: '12px', md: '1.8vw', lg: '18px' }}
            fontWeight="bold"
          >
            {titlesInfo[3]?.dataSecondStep?.name}
          </Text>
          <Text
            flex={1}
            color={gray}
            fontSize={{ sm: '12px', md: '1.8vw', lg: '18px' }}
            fontWeight="bold"
          >
            {titlesInfo[3]?.dataSecondStep?.cvu}
          </Text>
        </Box>
        <Box
          display="flex"
          flexDir="column"
          alignItems="center"
          justifyContent="space-around"
        >
          <Text
            flex={1}
            color={gray}
            fontSize={{ sm: '12px', md: '1.8vw', lg: '18px' }}
            fontWeight="medium"
          >
            Concepto
          </Text>
          <Text flex={1} fontSize={{ sm: '12px', md: '1.8vw', lg: '18px' }}>
            Varios
          </Text>
        </Box>
        <Box
          display="flex"
          flexDir="column"
          alignItems="center"
          justifyContent="space-around"
        >
          <Text
            flex={1}
            color={gray}
            fontSize={{ sm: '12px', md: '1.8vw', lg: '18px' }}
            fontWeight="medium"
          >
            Monto
          </Text>
          <Box display="flex" alignItems="center">
            <Text
              flex={1}
              fontWeight="medium"
              fontSize={{ sm: '20px', md: '30px', lg: '40px' }}
              mr={2}
            >
              $
              {`${amountToLocaleString}${addCommaZero(amountToLocaleString)}`}
            </Text>
            <TouchableOpacity onPress={onPressEditAmount}>
              <PencilEdit />
            </TouchableOpacity>
          </Box>
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <Button
          onPress={onPressCancel}
          customStyle={styles.cancelButton}
          fontColor={purple}
          testID='cancelTransfer'
        >
          Cancelar
        </Button>
        <Button onPress={onPress} customStyle={styles.button} testID='approveTransfer'>
          Transferir
        </Button>
      </Box>
    </Box>
  )
}

export default StepFour
