/* eslint-disable react/require-default-props */
import React from 'react'
import {
  TouchableHighlight,
  ViewStyle
} from 'react-native'
import { Spinner, Text } from '@chakra-ui/react'
import styles from './styles'

interface Props {
  onPress: () => any
  children: any
  disabled?: boolean
  loading?: boolean
  customStyle?: ViewStyle
  fixed?: boolean
  secondary?: boolean
  terciary?: boolean
  fromHome?: boolean
  testID?: string
  accessibilityLabelValue?: string
  fontColor?: any
}

function Button ({
  onPress,
  children,
  disabled,
  loading,
  customStyle = {},
  fixed,
  secondary,
  terciary,
  fromHome,
  testID = '',
  accessibilityLabelValue = '',
  fontColor
}: Props) {
  const onPressHandler = () => {
    if (!disabled && onPress) onPress()
  }
  return (
    <TouchableHighlight
      accessibilityLabel={accessibilityLabelValue}
      onPress={onPressHandler}
      testID={testID}
      disabled={loading || disabled}
      style={[
        styles.button,
        disabled && styles.disabled,
        fixed && styles.fixedBottom,
        customStyle,
        secondary && styles.secondary,
        terciary && styles.terciary,
        fromHome && styles.home,
        secondary && disabled && styles.disabledSecondary
      ]}
      underlayColor={secondary ? '#E7E2FF' : '#6C57DD'}
      delayPressIn={0}
    >
      <Text
        alignSelf="center"
        color={fontColor || 'white'}
        fontWeight={500}
        fontSize="16px"
      >
        {loading ? <Spinner /> : children}
      </Text>
    </TouchableHighlight>
  )
}

export default Button
