import { api } from '@qirapagos/lib/config/api'
import { insertKeyEndpoint } from './utils'
import { QuotaBody } from 'store/contracts/types'

const GET_CURRRENCIES = 'currencies'
const GET_EXCHANGERS = 'company/exchangers'
const GET_CONDITION_TYPE = 'conditions'
const GET_GRAIN_TYPE = 'grains'
const HARVERST_DATE = 'harvestDates'
const GET_AGENTS = 'representation/company'
const GET_ZONES = 'zones'
const GET_OPERATION_DETAIL = 'operation'
const NEW_OPERATION_PRICE_TO_SET = 'priceFixingOperations'
const NEW_OPERATION_FIXED_PRICE = 'operation'
const NEW_CUPO = 'cupo'
const NEW_FIX = 'priceFixing'
const GET_PRICE_FIXINGS_BY_ID = 'priceFixingsById'
const GET_PRICE_FIXINGS_ALL = 'priceFixingsByIdOperation'
const GET_CONTRACTS = 'operation/company'
const GET_COUNTER_OFFER = 'operation/business'

export const getCurrencies = (token: string, keyEndpoints: string) =>
  api.get(
    insertKeyEndpoint(GET_CURRRENCIES, keyEndpoints),
    {},
    { headers: { token } }
  )

export const getConditionType = (token: string, keyEndpoints: string) =>
  api.get(
    insertKeyEndpoint(GET_CONDITION_TYPE, keyEndpoints),
    {},
    { headers: { token } }
  )

export const getExchangers = (token: string, keyEndpoints: string) =>
  api.get(
    insertKeyEndpoint(GET_EXCHANGERS, keyEndpoints),
    {},
    { headers: { token } }
  )

export const getGrainType = (token: string, keyEndpoints: string) =>
  api.get(
    insertKeyEndpoint(GET_GRAIN_TYPE, keyEndpoints),
    {},
    { headers: { token } }
  )

export const getHarvestDate = (token: string, keyEndpoints: string) =>
  api.get(
    insertKeyEndpoint(HARVERST_DATE, keyEndpoints),
    {},
    { headers: { token } }
  )

export const getAgents = (
  token: string | null,
  companyId: string | null,
  keyEndpoints: string
) =>
  api.get(
    insertKeyEndpoint(`${GET_AGENTS}/${companyId}`, keyEndpoints),
    {},
    { headers: { token } }
  )

export const getZones = (token: string | null, keyEndpoints: string) =>
  api.get(
    insertKeyEndpoint(GET_ZONES, keyEndpoints),
    {},
    { headers: { token } }
  )

export const getContracts = (
  companyId: number,
  token: string | null,
  keyEndpoints: string
) =>
  api.get(
    insertKeyEndpoint(`${GET_CONTRACTS}/${companyId}`, keyEndpoints),
    {},
    { headers: { token } }
  )

export const getOperationDetail = (
  operationId: number | string | null,
  token: string | null,
  keyEndpoints: string
) =>
  api.get(
    insertKeyEndpoint(
      `${GET_OPERATION_DETAIL}/${operationId}/all`,
      keyEndpoints
    ),
    {},
    { headers: { token } }
  )

export const getPriceFixations = (
  operationId: number,
  token: string | null,
  keyEndpoints: string
) =>
  api.get(
    insertKeyEndpoint(`${GET_PRICE_FIXINGS_ALL}/${operationId}`, keyEndpoints),
    {},
    { headers: { token } }
  )

export const newCupo = (
  data: object,
  token: string | null,
  keyEndpoints: string
) =>
  api.post(insertKeyEndpoint(NEW_CUPO, keyEndpoints), data, {
    headers: { token }
  })

export const getOperationShipping = (
  operationId: number,
  token: string | null,
  keyEndpoints: string
) =>
  api.get(
    insertKeyEndpoint(`${GET_OPERATION_DETAIL}/${operationId}`, keyEndpoints),
    {},
    { headers: { token } }
  )

export const getCounterOffer = (
  operationId: number,
  token: string | null,
  keyEndpoints: string
) =>
  api.get(
    insertKeyEndpoint(`${GET_COUNTER_OFFER}/${operationId}`, keyEndpoints),
    {},
    { headers: { token } }
  )

export const newFix = (
  body: object,
  token: string | null,
  keyEndpoints: string
) =>
  api.post(insertKeyEndpoint(NEW_FIX, keyEndpoints), body, {
    headers: { token }
  })

export const createSaleOrder = (body: object) => {
  const token = localStorage.getItem('token') as string
  return api.post(insertKeyEndpoint('sale-order'), body, {
    headers: { token }
  })
}

export const getSaleOrders = async () => {
  const token = localStorage.getItem('token') as string
  return api.get(insertKeyEndpoint('sale-order?offset=0&limit=1000'), {}, { headers: { token } })
}

export const replyCounterOffer = async (saleOrderId: number, accept: boolean) => {
  const token = localStorage.getItem('token') as string
  return api.patch(insertKeyEndpoint(`sale-order/counter-offer/${saleOrderId}`), { accept }, { headers: { token } })
}

export const getSaleOrder = async (id: number) => {
  const token = localStorage.getItem('token') as string
  return api.get(insertKeyEndpoint(`sale-order/${id}`), {}, { headers: { token } })
}

export const createQuotas = async (saleOrderId: number, body: QuotaBody) => {
  const token = localStorage.getItem('token') as string
  return api.post(insertKeyEndpoint(`quota/${saleOrderId}`), body, { headers: { token } })
}

export const getQuota = async (id: number) => {
  const token = localStorage.getItem('token') as string
  return api.get(insertKeyEndpoint(`quota/${id}`), {}, { headers: { token } })
}
