import React, { useCallback } from 'react'
import {
  Title,
  Button,
  SubTitle,
  Container,
  FieldContainer,
  ButtonContainer
} from './styles'
import { useForm } from 'react-hook-form'
import { Field } from 'components/molecules/Field'
import useNavigation from 'hooks/useNavigation'
import { useAppSelector } from 'hooks/redux'
import { useDispatch } from 'react-redux'
import { logOut, suspendCompany } from 'store/auth/thunks'
import { setShowDrawerError } from '@qirapagos/lib/store/modals/actions'

type FormValues = {
  suspect: string
}

const BlockAccount: React.FC = () => {
  const loading = useAppSelector((state) => state.auth.isLoading)
  const dispatch = useDispatch()
  const { onNavigate } = useNavigation()

  const { control, handleSubmit, errors } = useForm({
    defaultValues: { suspect: '' },
    mode: 'onChange'
  })

  const onSuspend = async (data: FormValues) => {
    const result = await dispatch(suspendCompany(data.suspect))
    if (result.error) {
      dispatch(setShowDrawerError(true, 'Error al suspender su cuenta', ''))
    } else {
      dispatch(logOut(onNavigate))
    }
  }

  const onNavigateTo = useCallback(() => {
    onNavigate('/content/wallet', {
    })
  }, [onNavigate])

  return (
    <Container>
      <Title>Usted va a bloquear su cuenta. ¿Desea continuar?</Title>
      <SubTitle>
        Antes de continuar nos gustaría saber cuál es su sospecha de operación fraudulenta.
        Intentaremos resolverlo lo antes posible.
      </SubTitle>
      <FieldContainer>
        <Field
          control={control}
          name="suspect"
          placeholder="Sospecho que..."
        />
      </FieldContainer>
      <ButtonContainer>
        <Button
          disabled={loading}
          onPress={onNavigateTo}
          secondary
        >
          No, volver atrás.
        </Button>
        <Button
          disabled={loading}
          onPress={handleSubmit(onSuspend)}
          loading={loading}
        >
          Sí, suspender
        </Button>
      </ButtonContainer>
    </Container>
  )
}

export default BlockAccount
