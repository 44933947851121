import React, { useCallback, useEffect, useState } from 'react'
import { Field } from 'components/molecules/Field'
import Helmet from 'components/utilities/Helmet'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { unsubscribeCompany, getMyData } from 'store/auth/thunks'
import { setShowDrawerError, setShowDrawerSuccess } from '@qirapagos/lib/store/modals/actions'
import { SCREEN_TITLES } from 'utils/navigation'
import { RenderIf } from 'components'
import useNavigation from 'hooks/useNavigation'
import {
  Title,
  Button,
  SubTitle,
  Container,
  FieldContainer,
  ButtonContainer
} from './styles'
import { setTwoFactorVerified } from '@qirapagos/lib/store/auth/actions'
import TwoFactorAuthentication from 'components/molecules/TwoFactorAuthentication'

const Steps = {
  TWO_FACTOR: 'TWO_FACTOR',
  UNSUBSCRIBE: 'UNSUBSCRIBE'
}

const Unsubscribe = () => {
  const dispatch = useDispatch()
  const [step, setStep] = useState(Steps.TWO_FACTOR)
  const { isLoading } = useSelector((state: any) => state.auth)
  const { control, handleSubmit } = useForm()
  const { onNavigate } = useNavigation()

  useEffect(() => {
    dispatch(getMyData())
  }, [dispatch])

  const onTwoFactorSuccess = useCallback(() => {
    setStep(Steps.UNSUBSCRIBE)
  }, [setStep])

  const onCancel = useCallback(() => {
    onNavigate('/content/my-data', { title: 'Mis datos' })
    dispatch(setTwoFactorVerified(false))
  }, [onNavigate, dispatch])

  const onPressSubmit = useCallback(async (values) => {
    try {
      await dispatch(unsubscribeCompany(values.reason))
      dispatch(
        setShowDrawerSuccess(
          true,
          true,
          '¡Listo!',
          'Ya cancelamos tu cuenta.',
          {
            path: '/'
          }
        )
      )
    } catch (error) {
      dispatch(
        setShowDrawerError(
          true,
          'No podemos completar la solicitud de baja en QIRA Pagos.',
          '',
          {
            path: '/content/my-data/unsubscribe'
          }
        )
      )
    }
  }, [dispatch])

  return (
    <>
      <RenderIf isTrue={step === Steps.TWO_FACTOR}>
        <TwoFactorAuthentication callback={onTwoFactorSuccess} onlyBySMS />
      </RenderIf>
      <RenderIf isTrue={step === Steps.UNSUBSCRIBE}>
        <Container>
          <Helmet title={SCREEN_TITLES.UNSUBSCRIPTION} />
          <Title>¿Estás  seguro que querés dar de baja tu cuenta en QIRA Pagos?</Title>
          <SubTitle>
            Antes de continuar, nos gustaría saber el motivo de tu baja. Tu respuesta nos ayuda a mejorar.
          </SubTitle>
          <FieldContainer>
            <Field
              control={control}
              name="reason"
              placeholder="Motivo de mi baja..."
            />
          </FieldContainer>
          <ButtonContainer>
            <Button
              secondary
              onPress={onCancel}
              disabled={isLoading}
            >
              Cancelar
            </Button>
            <Button
              onPress={handleSubmit(onPressSubmit)}
              disabled={isLoading}
              loading={isLoading}
            >
              Darme de baja
            </Button>
          </ButtonContainer>
        </Container>
      </RenderIf>
    </>
  )
}

export default Unsubscribe
