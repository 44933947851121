import * as React from "react"
import Svg, { SvgProps, Ellipse, Path } from "react-native-svg"

function TransferRecieve(props: SvgProps) {
  return (
    <Svg
      width={43}
      height={42}
      viewBox="0 0 43 42"
      fill="none"
      {...props}
    >
      <Ellipse cx={21.232} cy={21} rx={17.188} ry={17} fill="#fff" />
      <Path
        d="M41.465 21c0 11.035-9.048 20-20.233 20C10.048 41 1 32.035 1 21S10.048 1 21.232 1c11.185 0 20.233 8.965 20.233 20z"
        fill="#fff"
        stroke="#5AB0FF"
        strokeWidth={2}
      />
      <Path
        d="M25.594 16.593l-9.002 9.001M23.957 25.594h-7.365V18.23"
        stroke="#5AB0FF"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default TransferRecieve
