/* eslint-disable react/require-default-props */
import React, { ReactNode } from 'react'
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable
} from '@tanstack/react-table'
import { TableActionButton } from 'components'
import { Flex } from '@chakra-ui/react'
import CustomText from 'components/atoms/CustomText'
import TableDesktopSkeleton from 'assets/skeletons/TableDesktopSkeleton'
import EmptyCabinetSVG from 'assets/svg/EmptyCabinetSVG'
import {
  EmptyTextContainer,
  HeaderContainer,
  IconContainer,
  SvgContainer,
  TableContainer,
  TableDataItem,
  TableHeader,
  TableHeaderItem,
  TableRow,
  TableStyled,
  Title
} from './styles'

export enum TABLE {
  ACTION = 'ACTION'
}

interface Action {
  title: string;
  onPress: () => void
}

interface Config {
 id: string;
 header: string
 meta?: {
  formatter?: (value: any) => React.ReactNode
  actions?: (row: any) => Action[]
 }
}

interface Props {
  title: string
  data: any[]
  config: Config[]
  isLoading?: boolean
  icon?: ReactNode
  onClick?: () => void
  emptyText?: string
}

const Table: React.FC<Props> = ({
  title,
  icon,
  data,
  onClick = () => null,
  config,
  isLoading,
  emptyText = ''
}) => {
  const isEmpty = data.length === 0

  const cellGenerator = (id: string, info: any, meta: Config['meta']) => {
    const { formatter, actions } = meta || {}
    if (id === TABLE.ACTION && actions) {
      return (
        <TableActionButton
          placement="left"
          dotActionConfig={actions(info)}
        />
      )
    }
    if (formatter) {
      return (
        <CustomText size="xmedium" weight="mediumfont">
          {formatter(info.getValue())}
        </CustomText>
      )
    }
    return (
      <CustomText size="xmedium" weight="mediumfont">
        {info.getValue() || 'Sin Datos'}
      </CustomText>
    )
  }

  const columns: ColumnDef<any>[] = config.map((column: any) => {
    const { id, header, meta } = column
    return {
      accessorKey: id,
      cell: (info: any) => cellGenerator(id, info, meta),
      header
    }
  })

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel()
  })

  if (isLoading) {
    return <TableDesktopSkeleton />
  }

  return (
    <TableContainer>
      <HeaderContainer>
        <Flex>
          <Title>{title}</Title>
          {icon && (
            <IconContainer>
              <button type="button" onClick={onClick}>
                {icon}
              </button>
            </IconContainer>
          )}
        </Flex>
      </HeaderContainer>
      {isEmpty
        ? (
          <SvgContainer id="empty-list-movements">
            <EmptyCabinetSVG />
            <EmptyTextContainer>{emptyText}</EmptyTextContainer>
          </SvgContainer>
          )
        : (
          <TableStyled>
            <TableHeader>
              {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header, index) => (
                    <TableHeaderItem index={index} key={header.id}>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </TableHeaderItem>
                  ))}
                </tr>
              ))}
            </TableHeader>
            <tbody id="table-body">
              {table.getRowModel().rows.map((row, index) => (
                <TableRow index={index} key={row.id}>
                  {row.getVisibleCells().map((cell, index) => (
                    <TableDataItem index={index} key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableDataItem>
                  ))}
                </TableRow>
              ))}
            </tbody>
          </TableStyled>
          )}
    </TableContainer>
  )
}

export default Table
