import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  setShowDrawerNotificationsDetails,
  setShowDrawerNotification
} from '@qirapagos/lib/store/modals/actions'
import BackArrow from 'assets/svg/BackArrow'
import {
  Container,
  Drawer,
  HeaderTitle,
  Title,
  Header,
  Body,
  EmptyDiv
} from './styles'
import { RootState } from 'store'
import { Notification } from 'store/notifications/types'
import { purple } from 'theme/colors'
import { findUrlRegExp } from './types'

const DrawerNotificationsDetails: React.FC = () => {
  const { showDrawerNotificationDetail } = useSelector(
    (state: RootState) => state.modal
  )

  const notification = useSelector((state: RootState) =>
    state.notifications.pushNotifications.find(
      (item: Notification) => item.id === state.notifications.selected
    )
  )

  const dispatch = useDispatch()

  const renderBodyWithLinks = () => {
    if (notification?.body) {
      const parts = notification.body.split(' ')

      return parts.map(part => {
        if (findUrlRegExp.test(part)) {
          return (
            <Title>
              <a
                href={part}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: purple, textDecorationLine: 'underline' }}
              >
                {`${part}`}
              </a>
            </Title>
          )
        }

        return ` ${part} `
      })
    }

    return null
  }

  return (
    <Drawer
      open={showDrawerNotificationDetail}
      onClose={() => {
        dispatch(setShowDrawerNotificationsDetails(false))
        dispatch(setShowDrawerNotification(false))
      }}
      direction="right"
    >
      <Container>
        <Header>
          <button
            type="button"
            onClick={() => {
              dispatch(setShowDrawerNotificationsDetails(false))
              dispatch(setShowDrawerNotification(true))
            }}
          >
            <BackArrow />
          </button>
          <HeaderTitle>{notification?.title}</HeaderTitle>
          <EmptyDiv />
        </Header>
        <Body>
          <Title>
            {renderBodyWithLinks()}
          </Title>
          <Title>
            {`Fecha: ${new Date(
            notification?.createdAt || ''
          ).toLocaleDateString()}`}
          </Title>
        </Body>
      </Container>
    </Drawer>
  )
}

export default DrawerNotificationsDetails
