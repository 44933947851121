import React from 'react'
import { ViewStyle } from 'react-native'
import ButtonLine from '@qirapagos/lib/components/atoms/ButtonLine'
import { ViewTitle, ViewChildren, ViewContainer, TextTitle } from './styles'
import RenderIf from 'components/utilities/RenderIf'

interface Props {
  title: string
  children: React.ReactNode
  customStyle?: ViewStyle
  childrenCustomStyle?: ViewStyle
  link?: string
  onPressLink?: () => void
  testID?: string
}

const FormStepBox: React.FC<Props> = ({
  title,
  children,
  testID,
  customStyle,
  childrenCustomStyle,
  link = '',
  onPressLink = () => null
}) => {
  return (
    <ViewContainer style={customStyle} testID={testID}>
      <ViewTitle>
        <TextTitle>{title}</TextTitle>
        <RenderIf isTrue={!!link}>
          <ButtonLine
            onPress={onPressLink}
            customStyle={{ marginBottom: 0, marginRight: 15 }}
          >
            {link}
          </ButtonLine>
        </RenderIf>
      </ViewTitle>
      <ViewChildren style={childrenCustomStyle}>{children}</ViewChildren>
    </ViewContainer>
  )
}

export default FormStepBox

FormStepBox.defaultProps = {
  customStyle: {},
  childrenCustomStyle: {},
  link: '',
  onPressLink: () => null,
  testID: ''
}
