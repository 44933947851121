import React from 'react'
import { View, ViewStyle } from 'react-native'
import styles from './styles'
import { StatusType, getStatusColor } from './constants'
import CustomText from '@qirapagos/web/src/components/atoms/CustomText'

interface Props {
  statusType: StatusType | string
  customStyle?: ViewStyle
  fontSize?: string | number
  testID?: string
}

const Status: React.FC<Props> = ({
  statusType,
  customStyle = {},
  fontSize,
  testID
}) => {
  const { text, font, background } = getStatusColor(statusType) // aproved
  return (
    <View
      style={[styles.content, { backgroundColor: background }, customStyle]}
      testID={testID}
    >
      <CustomText size={fontSize} weight="semibold" color={font}>
        {text}
      </CustomText>
    </View>
  )
}

export default Status

Status.defaultProps = {
  customStyle: {},
  fontSize: 'small',
  testID: ''
}
