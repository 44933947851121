import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  content: {
    borderRadius: 6,
    paddingHorizontal: 16,
    paddingVertical: 2,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center'
  }
})
