import React from 'react'
import { useDispatch } from 'react-redux'
import useNavigation from 'hooks/useNavigation'
import Button from '@qirapagos/lib/components/atoms/Button/index.web'
import { removeNewPasswordError } from '@qirapagos/lib/store/auth/actions'
import { Container, Title, Expired, buttonStyles } from './styles'

const ChangePassword: React.FC = () => {
  const { onNavigate } = useNavigation()
  const dispatch = useDispatch()

  return (
    <Container>
      <Title>
        El tiempo para restablecer tu contraseña se ha vencido, intentá nuevamente.
      </Title>
      <Expired />
      <Button
        customStyle={buttonStyles}
        onPress={() => {
          dispatch(removeNewPasswordError())
          onNavigate('/auth/forgot-password')
        }}
      >
        Restablecer contraseña
      </Button>
    </Container>
  )
}

export default ChangePassword
