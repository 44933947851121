import {
  ModalActionTypes,
  SHOW_DRAWER,
  SHOW_DRAWER_SUCCESS,
  SHOW_DRAWER_ERROR,
  SHOW_DRAWER_STATEMENT,
  SHOW_DRAWER_ACCOUNT,
  SHOW_DRAWER_MY_AGENT,
  SHOW_OUT_OF_TIME_RANGE,
  SHOW_DRAWER_NOTIFICATION,
  SHOW_DRAWER_CONTRACT_DETAIL,
  SHOW_HELP_DRAWER,
  LOGOUT,
  SHOW_DRAWER_NOTIFICATION_DETAIL,
  SHOW_DRAWER_GRAINS,
  IDrawerInfo
} from './types'

export interface State {
  showModal:
    | 'account'
    | 'options'
    | 'help'
    | 'howWorks'
    | 'selectorEnterprise'
    | 'filter'
    | 'signature'
    | null
  showDrawer: boolean
  drawerConfig: any
  selectedReceipt: string
  drawerInfo: Partial<IDrawerInfo>
  showDrawerSuccess: {
    show: boolean,
    success: boolean,
    title: string,
    body: string,
    navigation: {
      path: string,
      title: string,
      fromScreen: string
    }
  }
  showDrawerError: {
    show: false,
    title: string,
    body: string,
    navigation: {
      path: string,
      title: string,
      fromScreen: string
    },
    btnText?: string,
    callback?: () => void,
  }
  showStatementDrawer: boolean
  showDrawerNotificationDetail: boolean
  showAccountDrawer: boolean
  showDrawerMyAgent: boolean
  showDrawerOfTimeRange: boolean
  showDrawerNotification: boolean
  showDrawerContractDetail: boolean
  drawerGrains: {
    showDrawerGrains: boolean,
    drawerConfig: {
      title: string,
      config: string
    },
    drawerInfo: any
  }
  showHelpDrawer: {
    isOpen: boolean
    title: string
    text: string
  }
}

const initialState: State = {
  showModal: null,
  showDrawer: false,
  drawerConfig: {
    title: '',
    config: ''
  },
  showStatementDrawer: false,
  showAccountDrawer: false,
  selectedReceipt: '',
  drawerInfo: undefined,
  showDrawerSuccess: {
    show: false,
    success: false,
    title: '',
    body: '',
    navigation: {
      path: '',
      title: '',
      fromScreen: ''
    }
  },
  showDrawerError: {
    show: false,
    title: '',
    body: '',
    navigation: {
      path: '',
      title: '',
      fromScreen: ''
    },
    btnText: '',
    callback: undefined
  },
  showDrawerMyAgent: false,
  showDrawerOfTimeRange: false,
  showDrawerNotification: false,
  showDrawerNotificationDetail: false,
  showDrawerContractDetail: false,
  drawerGrains: {
    showDrawerGrains: false,
    drawerConfig: {
      title: '',
      config: ''
    },
    drawerInfo: {}
  },
  showHelpDrawer: {
    isOpen: false,
    title: '',
    text: ''
  }
}

// TODO: add typing for actions

export default function modalReducer (state = initialState, action: any): State {
  switch (action.type) {
    case ModalActionTypes.SHOW_MODAL:
      return {
        ...state,
        showModal: action.payload
      }
    case SHOW_DRAWER: {
      const { showDrawer, drawerConfig, selectedReceipt, drawerInfo } =
        action.payload
      return {
        ...state,
        showDrawer,
        drawerConfig,
        selectedReceipt,
        drawerInfo
      }
    }
    case SHOW_DRAWER_STATEMENT: {
      const { showStatementDrawer } = action.payload
      return {
        ...state,
        showStatementDrawer
      }
    }
    case SHOW_DRAWER_ACCOUNT: {
      const { showDrawerAccount, drawerConfig, selectedReceipt, drawerInfo } =
        action.payload
      return {
        ...state,
        showAccountDrawer: showDrawerAccount,
        drawerConfig,
        selectedReceipt,
        drawerInfo
      }
    }
    case SHOW_DRAWER_SUCCESS: {
      const { show, success, title, body, navigation } = action.payload
      return {
        ...state,
        showDrawerSuccess: {
          show,
          success,
          title,
          body,
          navigation
        }
      }
    }
    case SHOW_DRAWER_ERROR: {
      return {
        ...state,
        showDrawerError: {
          ...action.payload,
          btnText: action.payload.btnText || 'Aceptar'
        }
      }
    }
    case SHOW_DRAWER_MY_AGENT: {
      return {
        ...state,
        showDrawerMyAgent: action.payload
      }
    }
    case SHOW_OUT_OF_TIME_RANGE: {
      return {
        ...state,
        showDrawerOfTimeRange: action.payload
      }
    }
    case SHOW_DRAWER_NOTIFICATION: {
      return {
        ...state,
        showDrawerNotification: action.payload
      }
    }
    case SHOW_DRAWER_NOTIFICATION_DETAIL: {
      return {
        ...state,
        showDrawerNotificationDetail: action.payload
      }
    }
    case SHOW_DRAWER_CONTRACT_DETAIL: {
      return {
        ...state,
        showDrawerContractDetail: action.payload
      }
    }
    case SHOW_HELP_DRAWER: {
      return {
        ...state,
        showHelpDrawer: action.payload
      }
    }
    case SHOW_DRAWER_GRAINS: {
      const { showDrawerGrains, drawerConfig, drawerInfo } = action.payload
      return {
        ...state,
        drawerGrains: {
          showDrawerGrains: showDrawerGrains,
          drawerConfig,
          drawerInfo
        }
      }
    }
    case LOGOUT:
      return {
        ...initialState
      }
    default:
      return state
  }
}
