import React, { useEffect, useMemo, useState } from 'react'
import * as yup from 'yup'
import Helmet from 'components/utilities/Helmet'
import { useFormik } from 'formik'
import { debounce } from 'lodash'
import { useDispatch } from 'react-redux'
import { changeAddress, getMyData } from 'store/auth/thunks'
import Button from '@qirapagos/web/src/components/atoms/Button'
import {
  setShowDrawerError,
  setShowDrawerSuccess
} from '@qirapagos/lib/store/modals/actions'
import { SCREEN_TITLES } from 'utils/navigation'
import { ErrorMessages } from '@qirapagos/lib/utils/common'
import useNavigation from 'hooks/useNavigation'
import {
  Title,
  Form,
  FieldContainer,
  FieldRow,
  LinkTitle,
  LinkHeader,
  LinkContainer,
  ButtonContainer,
  ButtonsContainer
} from './styles'
import { useAppSelector } from 'hooks/redux'
import { cleanLocations } from 'store/location/actions'
import { getLocations, getStates } from 'store/location/thunks'
import AddressEditSkeleton from 'assets/skeletons/AddressEditSkeleton'
import { RootState } from 'store'
import { TextField } from 'components/shared/TextField'
import { SelectField } from 'components/shared/SelectField'
import { AutocompleteField, Option } from 'components/shared/AutocompleteField'

interface FormValues {
  address: string;
  number: number;
  locationId: number;
  stateId: string | undefined; // Should be a number but it is not working with Formik when as a default value
  postalCode: string;
}

const ChangeAddressMyData = () => {
  const dispatch = useDispatch()
  const { onNavigate } = useNavigation()

  // Used to show the user´s Location as an Autocomplete option when is editing
  const [defaultLocation, setDefaultLocation] = useState<Option>()
  const { isLoading, isMyDataEditing, myData } = useAppSelector((state: RootState) => state.auth)
  const { isLoading: isLoadingLocations, states, locations } = useAppSelector((state: RootState) => state.location)

  useEffect(() => {
    if (!myData) {
      dispatch(getMyData())
    } else {
      setDefaultLocation({
        title: myData?.Location?.location,
        id: myData?.Location?.id
      })
    }
  }, [dispatch, myData])

  useEffect(() => {
    dispatch(getStates())
  }, [dispatch])

  const onSubmit = async (values: FormValues) => {
    try {
      await dispatch(changeAddress({
        address: values.address,
        number: values.number,
        locationId: values.locationId,
        postalCode: values.postalCode
      }))
      dispatch(
        setShowDrawerSuccess(
          true,
          true,
          '¡Listo!',
          'Editaste tu dirección.',
          {
            path: '/content/my-data'
          }
        )
      )
    } catch (error: any) {
      dispatch(
        setShowDrawerError(
          true,
          error?.toString() || ErrorMessages.ADDRESS_EDIT,
          '',
          {
            path: '/content/my-data'
          }, 'Reintentar'
        )
      )
    }
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      address: myData?.address,
      number: myData?.number,
      locationId: myData?.Location?.id,
      stateId: myData?.Location?.Province?.id.toString() || '',
      postalCode: myData?.postalCode
    },
    initialTouched: {
      locationId: true
    },
    validationSchema: yup.object().shape({
      address: yup
        .string()
        .matches(/^[a-zA-Z0-9áéíóúÁÉÍÓÚ][a-zA-Z0-9\s-áéíóúÁÉÍÓÚ]*$/,
          'Formato no valido. Utilice letras, números, espacios entre palabras y guiones medios')
        .required('Campo requerido'),
      number: yup
        .number()
        .typeError('Formato no valido. Utilice solo números')
        .required('Campo requerido'),
      locationId: yup
        .string()
        .required('Campo requerido'),
      stateId: yup
        .string()
        .required('Campo requerido'),
      postalCode: yup
        .string()
        .matches(/([a-hj-np-z])([\d]{1,4})([a-z]{3})/i, 'Formato no valido. Utilice formato CPA')
        .required('Campo requerido')
    }),
    onSubmit
  })

  const onCancel = () => {
    onNavigate('/content/my-data', { title: 'Mis datos' })
  }

  const debouncedSearchLocations = debounce(async (_, query) => {
    if (query.length > 3) {
      setDefaultLocation(undefined)
      dispatch(getLocations({ search: query, stateId: Number(formik.values.stateId) }))
    }
  }, 300)

  const statesList = useMemo(() => {
    return states?.map((state) => ({
      value: state.id.toString(),
      label: state.province
    }))
  }, [states])

  const locationsList = useMemo(() => {
    if (defaultLocation) {
      return [defaultLocation]
    }
    return locations.map((location) => ({
      title: location.location,
      id: location.id
    }))
  }, [locations, defaultLocation])

  if (isLoading) {
    return <AddressEditSkeleton />
  }

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Helmet title={SCREEN_TITLES.CHANGE_ADDRESS} />
      <Title>Editar Domicilio</Title>
      <FieldRow>
        <FieldContainer>
          <TextField
            formik={formik}
            name="address"
            label="Calle"
            type="text"
          />
        </FieldContainer>
        <FieldContainer>
          <TextField
            formik={formik}
            name="number"
            label="Altura"
            type="number"
          />
        </FieldContainer>
      </FieldRow>
      <FieldRow>
        <FieldContainer>
          <SelectField
            formik={formik}
            name="stateId"
            label="Provincia"
            options={statesList}
            onChange={() => {
              // Remove default values used when is editing
              setDefaultLocation(undefined)
              // Clean locations list in Redux
              dispatch(cleanLocations())
              // Clean location form field
              formik.setFieldValue('locationId', '')
            }}
          />
        </FieldContainer>
        <FieldContainer>
          <AutocompleteField
            formik={formik}
            name="locationId"
            label="Ciudad"
            loading={isLoadingLocations}
            onInputChange={debouncedSearchLocations}
            options={locationsList}
          />
        </FieldContainer>
      </FieldRow>
      <FieldRow>
        <FieldContainer>
          <TextField
            formik={formik}
            name="postalCode"
            label="Código postal"
            type="text"
          />
        </FieldContainer>
        <LinkContainer>
          <LinkHeader>Debe obtener el código postal en el siguiente link</LinkHeader>
          <LinkTitle>
            <a href='https://www.correoargentino.com.ar/formularios/cpa' target='_blank' rel="noreferrer">
              https://www.correoargentino.com.ar/formularios/cpa
            </a>
          </LinkTitle>
        </LinkContainer>
      </FieldRow>
      <ButtonsContainer>
        <ButtonContainer>
          <Button secondary onPress={onCancel} disabled={isMyDataEditing}>
            Cancelar
          </Button>
        </ButtonContainer>
        <ButtonContainer>
          <Button loading={isMyDataEditing} disabled={!formik.dirty}>
            Guardar
          </Button>
        </ButtonContainer>
      </ButtonsContainer>
    </Form>
  )
}

export default ChangeAddressMyData
