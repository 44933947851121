import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setShowDrawerNotification } from '@qirapagos/lib/store/modals/actions'
import BackArrow from 'assets/svg/BackArrow'
import { NotificationCard } from 'components'
import { TouchableOpacity } from 'react-native'
import { formatDateToString, formatDateWithMonth } from 'utils/common'
import {
  Container,
  Header,
  Title,
  Drawer,
  EmptyLabel,
  EmptyList,
  List,
  Trash,
  WarningBox,
  OfflineHandling
} from './styles'
import { getNotifications, removeNotifications } from 'store/notifications/thunks'
import { Notification } from 'store/notifications/types'
import { RootState } from 'store'

const DrawerNotifications: React.FC = () => {
  const [deleteAll, setDeleteAll] = useState(false)
  const { showDrawerNotification } = useSelector((state: RootState) => state.modal)
  const { pushNotifications } = useSelector((state: RootState) => state.notifications)
  const dispatch = useDispatch()

  const onClose = () => {
    setDeleteAll(false)
    dispatch(setShowDrawerNotification(false))
    if (deleteAll) {
      dispatch(removeNotifications())
    }
  }

  useEffect(() => {
    dispatch(getNotifications())
  }, [dispatch])

  const RenderItem = React.memo(({ item }: { item: Notification }) => {
    const { title, body, id, createdAt, viewed } = item

    return (
      <NotificationCard
        title={title}
        body={body}
        createdAt={formatDateToString(new Date(createdAt)) || formatDateWithMonth(createdAt)}
        id={id}
        viewed={viewed}
      />
    )
  })

  const renderList = () => {
    if (!deleteAll) {
      if (pushNotifications.length) {
        return (
          <List>
            {
              pushNotifications?.map((notification: Notification) =>
                <RenderItem item={notification} />
              )
            }
          </List>
        )
      }
      return (
        <EmptyList>
          <OfflineHandling />
          <EmptyLabel>
            No tenés notificaciones
          </EmptyLabel>
        </EmptyList>
      )
    }
    return (
      <WarningBox
        title="Se eliminaron todas las notificaciones"
        buttonText="Deshacer"
        onclick={() => {
          setDeleteAll(false)
        }}
      />
    )
  }

  return (
    <Drawer
      open={showDrawerNotification}
      onClose={onClose}
      direction="right"
    >
      <Container>
        <Header>
          <button
            type="button"
            onClick={onClose}
          >
            <BackArrow />
          </button>
          <Title>Notificaciones</Title>
          <TouchableOpacity onPress={() => {
            setDeleteAll(true)
          }}
          >
            <Trash selected={deleteAll} />
          </TouchableOpacity>
        </Header>
        {renderList()}
      </Container>
    </Drawer>
  )
}

export default DrawerNotifications
