import { MenuState } from './types'

const initialState: MenuState = {
  vendor: {
    name: '',
    lastName: '',
    phone: '',
    email: ''
  },
  isLoading: false,
  typepaymentmethod: [],
  paymentmethod: [],
  redeemers: [],
  countPageRedeemers: 1,
  agent: {
    relationshipId: 0,
    companyId: 0,
    cuit: '',
    companyName: '',
    address: '',
    addressNumber: '',
    email: '',
    phone: '',
    cvu: '',
    alias: '',
    bank: ''
  },
  hasAgent: false,
  clientagents: [],
  multiEnterpriseAssociation: []
}

export default initialState
