import styled from 'styled-components'
import { StyleSheet } from 'react-native'
import { disabledGray } from '@qirapagos/lib/theme/colors'
import { DEVICES } from 'theme/devicesSizes'

export const styles = StyleSheet.create({
  customTitle: {
    marginTop: '9%',
    color: disabledGray
  },
  customText: {
    marginBottom: '6%',
    color: disabledGray
  },
  statusStyle: { margin: '5%' }
})

export const BoxContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-content: space-between;
  justify-content: space-evenly;
  background-color: white;
  width: 45%;
  height: 100%;
  border-radius: 6px;
  margin-right: 2%;
  box-shadow: 0px 4px 74px rgba(0, 0, 0, 0.13);
  max-width: 500px;

  @media ${DEVICES.tablet} {
    width: 70%;
  }

  @media ${DEVICES.mobile} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 70%;
    height: 200px;
  }
`

export const TooltipContainer = styled.div`
  position: absolute;
  right: -20px;
  top: 0px;
`
