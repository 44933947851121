import {
  SHOW_DRAWER,
  SHOW_DRAWER_SUCCESS,
  SHOW_DRAWER_ERROR,
  SHOW_DRAWER_STATEMENT,
  SHOW_DRAWER_ACCOUNT,
  SHOW_DRAWER_MY_AGENT,
  SHOW_OUT_OF_TIME_RANGE,
  SHOW_DRAWER_NOTIFICATION,
  ModalActionTypes,
  SHOW_DRAWER_CONTRACT_DETAIL,
  SHOW_HELP_DRAWER,
  SHOW_DRAWER_GRAINS,
  SHOW_DRAWER_NOTIFICATION_DETAIL,
  IDrawerInfo
} from './types'

export const setShowDrawer = (
  showDrawer: boolean,
  selectedReceipt: string,
  drawerConfig?: any,
  drawerInfo?: Partial<IDrawerInfo>
) => ({
  type: SHOW_DRAWER,
  payload: {
    showDrawer,
    selectedReceipt,
    drawerConfig: {
      title: drawerConfig.title,
      config: drawerConfig.config
    },
    drawerInfo
  }
})

const setShowModal = (modal: string) => ({
  type: ModalActionTypes.SHOW_MODAL,
  payload: modal
})

export const setShowDrawerSuccess = (
  show: boolean,
  success?: boolean,
  title?: string,
  body?: string,
  navigation?: any
) => ({
  type: SHOW_DRAWER_SUCCESS,
  payload: {
    show,
    success,
    title,
    body,
    navigation
  }
})

export const setShowDrawerError = (
  show: boolean,
  title?: string,
  body?: string,
  navigation?: any,
  btnText?: string,
  callback?: () => void
) => ({
  type: SHOW_DRAWER_ERROR,
  payload: {
    show,
    title,
    body,
    navigation,
    btnText,
    callback
  }
})

export const setShowDrawerStatement = (showStatementDrawer: boolean) => ({
  type: SHOW_DRAWER_STATEMENT,
  payload: {
    showStatementDrawer
  }
})

export const setShowDrawerAccount = (
  showDrawerAccount: boolean,
  selectedReceipt: string,
  drawerConfig?: any,
  drawerInfo?: Partial<IDrawerInfo>
) => ({
  type: SHOW_DRAWER_ACCOUNT,
  payload: {
    showDrawerAccount,
    selectedReceipt,
    drawerConfig: {
      title: drawerConfig.title,
      config: drawerConfig.config
    },
    drawerInfo
  }
})

export const setShowDrawerMyAgent = (showDrawerMyAgent: boolean) => ({
  type: SHOW_DRAWER_MY_AGENT,
  payload: showDrawerMyAgent
})
export const setShowDrawerOfTimeRange = (show: boolean) => ({
  type: SHOW_OUT_OF_TIME_RANGE,
  payload: show
})

export const setShowDrawerNotification = (show: boolean) => ({
  type: SHOW_DRAWER_NOTIFICATION,
  payload: show
})

export const setShowDrawerNotificationsDetails = (show: boolean) => ({
  type: SHOW_DRAWER_NOTIFICATION_DETAIL,
  payload: show
})

export const setShowDrawerContractDetail = (
  showDrawerContractDetail: boolean
) => ({
  type: SHOW_DRAWER_CONTRACT_DETAIL,
  payload: showDrawerContractDetail
})

export const setShowDrawerHelp = (data: any) => ({
  type: SHOW_HELP_DRAWER,
  payload: data
})

export const setShowDrawerGrains = (
  showDrawerGrains: boolean,
  drawerConfig?: any,
  drawerInfo?: any
) => ({
  type: SHOW_DRAWER_GRAINS,
  payload: {
    showDrawerGrains,
    drawerConfig: {
      title: drawerConfig.title,
      config: drawerConfig.config
    },
    drawerInfo
  }
})

export default setShowModal
