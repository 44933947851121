import { Colors } from '@qirapagos/lib/theme'

export enum StatusType {
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETE = 'COMPLETE',
  COUNTER_OFFER = 'COUNTER_OFFER',
  REJECTED = 'REJECTED',
  REMOVED = 'REMOVED',
}

export const STATUS_STYLES = {
  [StatusType.PENDING]: {
    text: 'PENDIENTE',
    font: 'black',
    background: Colors.grayBackLight
  },
  [StatusType.IN_PROGRESS]: {
    text: 'EN CURSO',
    font: 'black',
    background: Colors.newPurple
  },
  [StatusType.REMOVED]: {
    text: 'ELIMINADA',
    font: 'black',
    background: Colors.redSec
  },
  [StatusType.COUNTER_OFFER]: {
    text: 'CONTRAOFERTA RECIBIDA',
    font: 'black',
    background: Colors.blueSec
  },
  [StatusType.COMPLETE]: {
    text: 'FINALIZADA',
    font: 'black',
    background: Colors.greenSec
  },
  [StatusType.REJECTED]: {
    text: 'RECHAZADA',
    font: 'black',
    background: Colors.redSec
  }
}

export const getStatusColor = (status: StatusType) => STATUS_STYLES[status]
