import React from 'react'
import Drawer from 'react-modern-drawer'
import { setShowDrawerHelp } from '@qirapagos/lib/store/modals/actions'
import { useDispatch } from 'react-redux'
import BackArrowIcon from 'assets/svg/BackArrow'
import ContractDrawerLensIcon from 'assets/svg/ContractDrawerLens'
import {
  getDrawerStyles,
  Title,
  DownloadButton,
  HeaderContainer,
  DrawerContainer,
  TextContent,
  DrawerContentContainer,
  BackButton,
  ButtonContainer
} from './styles'
import { useAppSelector } from 'hooks/redux'

const DrawerConfirmation: React.FC = () => {
  const { title, text } = useAppSelector(
    (state) => state.modal.showHelpDrawer
  )
  const { showHelpDrawer } = useAppSelector(
    (state) => state.modal
  )
  const dispatch = useDispatch()
  const onClose = () => {
    const data = {
      isOpen: false,
      title,
      text
    }
    dispatch(setShowDrawerHelp(data))
  }

  return (
    <Drawer
      open={showHelpDrawer.isOpen}
      onClose={onClose}
      direction="right"
      style={getDrawerStyles()}
    >
      <DrawerContainer>
        <HeaderContainer>
          <BackButton onClick={onClose}>
            <BackArrowIcon />
          </BackButton>
          <Title>{title}</Title>
        </HeaderContainer>
        <DrawerContentContainer>
          <ContractDrawerLensIcon />
          <TextContent>{text}</TextContent>
        </DrawerContentContainer>
        <ButtonContainer>
          <DownloadButton onPress={onClose} accessibilityLabelValue="drawer-button">
            Entendido
          </DownloadButton>
        </ButtonContainer>
      </DrawerContainer>
    </Drawer>
  )
}

export default DrawerConfirmation
