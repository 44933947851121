import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import useServerTime from 'hooks/useServerTime'
import useHasSisa from '@qirapagos/lib/hooks/useHasSisa'
import WalletDesktopSkeleton from 'assets/skeletons/WalletDesktopSkeleton'

const SisaAndTimeCheck = ({ children }: any) => {
  const location = useLocation()
  const { isOutOfTimeRange } = useServerTime()
  const hasSisa = useHasSisa()

  if (hasSisa === undefined) return <WalletDesktopSkeleton />

  // if (isOutOfTimeRange) {
  //   return <Navigate to="/content/grains" state={{ from: location }} replace />
  // }
  if (hasSisa === false) {
    return (
      <Navigate
        to="/content/wallet"
        state={{ from: location, title: 'inicio' }}
        replace
      />
    )
  }

  return children
}

export default SisaAndTimeCheck
