import styled from 'styled-components'
import { DEVICES } from 'theme/devicesSizes'

export const Container = styled.div`
  height: 80vh;
  @media ${DEVICES.tablet} {
    max-height: 100%;
    overflow-y: scroll;
  }
`
export const ViewSubContainer = styled.div`
  margin-left: 3.7vw;
  width: 87%;
  padding-top: 2vw;
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media ${DEVICES.tablet} {
    width: 92.5%;
  }
`

export const ViewBannerContainer = styled.div``

export const ImageAdvertising = styled.img`
  width: 100%;
`

export const ViewColumnsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 36px;

  @media ${DEVICES.tablet} {
    height: 100%;
    flex-direction: column;
    align-items: center;
    gap: 50px;
  }
`

export const ViewLeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  gap: 50px;

  @media ${DEVICES.tablet} {
    width: 80%;
  }
`

export const ViewRightColumn = styled.div`
  width: 55%;

  @media ${DEVICES.tablet} {
    position: static;
    width: 80%;
    flex-shrink: 0;
    height: 500px;
  }
`
