import { useEffect, useState } from 'react'
import { getSimpleDataAsyncStorage } from '../utils/asyncStorage'
import { getDemoStatus } from '../utils/common'

export default function useHasOnboardingComplete () {
  const [hasOnboardingComplete, setHasOnboardingComplete] = useState<string>()
  useEffect(() => {
    const onboardingCheck = async () => {
      const companyStatus = await getSimpleDataAsyncStorage('companyStatus')

      const onboardingStatus = await getSimpleDataAsyncStorage(
        'onboardingStatus'
      )
      return setHasOnboardingComplete(
        getDemoStatus(Number(onboardingStatus), Number(companyStatus))
      )
    }
    onboardingCheck()
  }, [])

  return hasOnboardingComplete
}
