import React, { useEffect, useRef } from 'react'
import { Box, Spinner, Text } from '@chakra-ui/react'
import { Field } from 'components/molecules/Field'
import { Control } from 'react-hook-form'
import Button from '@qirapagos/lib/components/atoms/Button/index.web'
import { useAppSelector } from 'hooks/redux'
import { useDispatch } from 'react-redux'
import { getUserBalance } from 'store/transfers/thunks'
import { purple, red } from '@qirapagos/lib/theme/colors'
import { TSetState } from 'types/generics'
import useNavigation from 'hooks/useNavigation'
import { styles } from './styles'

interface Props {
  control: Control
  watch: any
  setIndex: TSetState<number>
  titlesInfo: any
  index: number
  setError: any
  errors: any
  setValue: any
}

const StepThree: React.FC<Props> = ({
  control,
  watch,
  setIndex,
  titlesInfo,
  index,
  setError,
  errors,
  setValue
}) => {
  const { balance, isLoading, account } = useAppSelector(
    state => state.transfers
  )
  const dispatch = useDispatch()
  const replacedValue = `${watch('transferInput')}`
    .replaceAll('.', '')
    .replace(',', '.')
    .replace('$', '')
  const balanceToNumber = +balance.replace(/[.]/g, '').replace(',', '.')
  const transferLimit = account?.transactionLimit
  const inputFocusRef = useRef<HTMLInputElement>(null)
  const { location } = useNavigation()
  const { amountAddressee } = location.state as any

  useEffect(() => {
    dispatch(getUserBalance())
  }, [dispatch])

  useEffect(() => {
    if (amountAddressee) {
      setValue('transferInput', String(amountAddressee).replace('.', ','))
    }
  }, [setValue, amountAddressee, isLoading, titlesInfo?.dataSecondStep?.name])

  const amountHigherThanBalance = balanceToNumber < Number(replacedValue)
  const amountHigherThanLimit = Boolean(transferLimit && Number(replacedValue) > transferLimit)

  const onPress = () => {
    if (+replacedValue === 0) {
      return setError('transferInput', {
        message: 'El monto ingresado debe ser mayor a 0'
      })
    }
    setIndex(3)
    titlesInfo.setAmountValue(replacedValue)
    titlesInfo.setComplete(true)
  }

  useEffect(() => {
    if (index === 2) {
      setTimeout(() => {
        if (inputFocusRef.current) {
          inputFocusRef.current.focus()
        }
      }, 0)
    }
  }, [index])
  if (titlesInfo?.dataSecondStep?.name === undefined) {
    return (
      <Box display="flex" flex={1} justifyContent="center" alignItems="center" h={300}>
        <Spinner />
      </Box>
    )
  }

  return (
    <Box display="flex" flexDir="column" flex={1} justifyContent="center">
      <Box
        display="flex"
        flexDir="column"
        justifyContent="space-around"
        flex={1}
        alignItems="center"
      >
        <Box display="flex" marginBottom="50px" marginTop="20px">
          <Text fontSize={{ sm: '15px', md: '18px', lg: '22px' }}>
            desde
            {' '}
            <Text color={purple} fontWeight="bold" as="span">
              QIRA: $
              {balance}
            </Text>
            {' '}
            a
            {' '}
            {titlesInfo?.dataSecondStep?.name}
          </Text>
        </Box>
        <Box display="flex">
          <Field
            ref={inputFocusRef}
            customStyle={{
              borderColor: 'transparent',
              backgroundColor: 'transparent',
              alignSelf: 'center',
              fontSize: '40px',
              fontWeight: 'bold',
              textAlign: 'center'
            }}
            name="transferInput"
            placeholder=""
            control={control}
            typeOfField="only-numbers"
            maxLength={15}
            error={errors.transferInput}
          />
        </Box>
        {amountHigherThanBalance && (
          <Text
            color={red}
            fontWeight="bold"
            visibility={amountHigherThanBalance ? 'visible' : 'hidden'}
          >
            El importe es superior a tu saldo
            {' '}
          </Text>
        )}
        {amountHigherThanLimit && !amountHigherThanBalance && (
          <>
            <Text textAlign="center" color={red} fontWeight="normal">
              El importe es superior a tu límite por transferencia.
            </Text>
            <Text textAlign="center" color={red} fontWeight="normal">
              Recordá que lo podés modificar desde &quot;Tu cuenta&quot;.
            </Text>
            <Text textAlign="center" color={red} fontWeight="semibold">
              {`Límite actual: $${account?.transactionLimit?.toFixed(2).replace('.', ',')}`}
            </Text>
          </>
        )}
      </Box>
      <Button
        onPress={onPress}
        customStyle={styles.button}
        disabled={amountHigherThanBalance || amountHigherThanLimit}
        testID='continueEnterAmount'
      >
        Continuar
      </Button>
    </Box>
  )
}

export default StepThree
