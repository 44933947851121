import React, { useEffect } from 'react'
import { MyDataCard } from 'components'
import { View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { getMyData } from 'store/auth/thunks'
import MyDataDesktopSkeleton from 'assets/skeletons/MyDataDesktopSkeleton'
import useNavigation from 'hooks/useNavigation'
import Helmet from 'components/utilities/Helmet'
import { setShowDrawerError } from '@qirapagos/lib/store/modals/actions'
import { SCREEN_TITLES } from 'utils/navigation'
import { setTwoFactorVerified } from '@qirapagos/lib/store/auth/actions'
import { useAppSelector } from 'hooks/redux'
import { RootState } from 'store'

const MyData = () => {
  const dispatch = useDispatch()
  const { isLoading } = useSelector((state: RootState) => state.contracts)
  const { myData } = useSelector((state: RootState) => state.auth)
  const { balance } = useSelector((state: RootState) => state.transfers)
  const { onNavigate } = useNavigation()
  const { userStatus } = useAppSelector((state: RootState) => state.auth)

  useEffect(() => {
    if (!myData) {
      dispatch(getMyData())
    }
  }, [dispatch, myData])

  const recoverPassword = {
    title: 'Cambiar Contraseña',
    callback: () =>
      onNavigate('/auth/forgot-password')
  }

  const changePassword = {
    title: 'Cambiar Contraseña',
    callback: () =>
      onNavigate('/content/my-data/change-password', { title: 'Mis datos' })
  }

  const changeEmail = {
    title: 'Cambiar email',
    callback: () =>
      onNavigate('/content/my-data/change-email', { title: 'Mis datos' })
  }

  const changePhone = {
    title: 'Cambiar phone',
    callback: () =>
      onNavigate('/content/my-data/change-phone', { title: 'Mis datos' })
  }

  const unsubscribe = {
    title: 'Quiero darme de baja',
    callback: () => {
      if (Number(balance) === 0) {
        onNavigate('/content/my-data/unsubscribe', { title: 'Mis datos' })
      } else {
        dispatch(setTwoFactorVerified(false))
        dispatch(
          setShowDrawerError(
            true,
            'No podemos completar la solicitud de baja en QIRA Pagos.',
            'Aún tenés dinero disponible en tu cuenta. Volvé a intentarlo luego de retirar el saldo.',
            {
              path: '/content/my-data'
            }
          )
        )
      }
    }
  }

  const regret = {
    title: 'Botón de arrepentimiento',
    callback: () => {
      if (Number(balance) === 0) {
        onNavigate('/content/my-data/unsubscribe', { title: 'Mis datos' })
      } else {
        dispatch(setTwoFactorVerified(false))
        dispatch(
          setShowDrawerError(
            true,
            'No podemos completar la solicitud de baja en QIRA Pagos.',
            'Aún tenés dinero disponible en tu cuenta. Volvé a intentarlo luego de retirar el saldo.',
            {
              path: '/content/my-data'
            }
          )
        )
      }
    }
  }

  const changeAddress = {
    title: 'Editar',
    callback: () =>
      onNavigate('/content/my-data/change-address', { title: 'Mis datos' })
  }

  if (isLoading || !myData) {
    return <MyDataDesktopSkeleton />
  }

  return (
    <View>
      <Helmet title={SCREEN_TITLES.MY_DATA} />
      <MyDataCard
        changePassword={userStatus === 'ONBOARDING' ? recoverPassword : changePassword}
        unsubscribe={unsubscribe}
        changeEmail={changeEmail}
        changePhone={changePhone}
        changeAddress={changeAddress}
        regret={regret}
      />
    </View>
  )
}

export default MyData
