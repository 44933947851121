
import React from 'react'
import { CustomList } from 'components'
import { styles } from './styles'
import { useAppSelector } from 'hooks/redux'

const AccountInfoList: React.FC = () => {
  const { account } = useAppSelector(state => state.transfers)
  const { alias, cvu, contactId } = account || {}
  const RECEIPT_ACCOUNT_INFO = [
    { ALIAS: alias },
    { CVU: cvu },
    { CC: 'Sin datos' },
    { 'CUIT / CUIL': contactId }
  ]

  return (
    <CustomList
      data={RECEIPT_ACCOUNT_INFO}
      positioning="vertical"
      customStyle={{ title: styles.title, flatList: styles.flatList }}
    />
  )
}

export default AccountInfoList
