import React from 'react'
import 'react-modern-drawer/dist/index.css'
import { useAppSelector } from 'hooks/redux'
import {
  DrawerReceipt,
  DrawerMyAgent,
  DrawerStatement,
  DrawerOutOfTimeRange,
  DrawerSuccess,
  DrawerError,
  DrawerNotifications,
  DrawerFilterDate,
  DrawerHelp,
  DrawerGrains,
  DrawerNotificationsDetails
} from 'components'
import { Drawers } from 'store/drawer/types'
import ConfirmNewSaleOrder from 'components/organisms/Drawers/ConfirmNewSaleOrder'
import SaleOrderDetails from 'components/organisms/Drawers/SaleOrderDetails'
import SaleOrderSuccess from 'components/organisms/Drawers/SaleOrderSuccess'
import SaleOrderError from 'components/organisms/Drawers/SaleOrderError'
import QuotaDetails from './QuotaDetails'
import QuotaCreateSuccess from './QuotaCreateSuccess'
import HowWorksQuotas from './HowWorksQuotas'
import ApplicationsDetails from './ApplicationsDetails'
import LiquidationsDetails from './LiquidationsDetails'

// TODO: implement new Drawer store logic
const DrawerContainer = () => {
  const {
    showDrawerError,
    showAccountDrawer,
    showDrawerMyAgent,
    showDrawerSuccess,
    showStatementDrawer,
    showDrawerNotification,
    showHelpDrawer,
    drawerGrains,
    showDrawerNotificationDetail
  } = useAppSelector(state => state.modal)

  const { actives } = useAppSelector(state => state.drawer)

  return (
    <>
      {showAccountDrawer && <DrawerReceipt />}
      {actives.includes(Drawers.CONFIRM_NEW_SALE_ORDER) && (
        <ConfirmNewSaleOrder />
      )}
      {actives.includes(Drawers.SALE_ORDER_DETAILS) && <SaleOrderDetails />}
      {actives.includes(Drawers.SALE_ORDER_SUCCESS) && <SaleOrderSuccess />}
      {actives.includes(Drawers.QUOTA_CREATE_SUCCESS) && <QuotaCreateSuccess />}
      {actives.includes(Drawers.SALE_ORDER_ERROR) && <SaleOrderError />}
      {actives.includes(Drawers.OUT_OF_TIME) && <DrawerOutOfTimeRange />}
      {actives.includes(Drawers.QUOTA_DETAILS) && <QuotaDetails />}
      {actives.includes(Drawers.HOW_WORKS_QUOTA) && <HowWorksQuotas />}
      {actives.includes(Drawers.APPLICATIONS_DETAILS) && (
        <ApplicationsDetails />
      )}
      {actives.includes(Drawers.LIQUIDATIONS_DETAILS) && (
        <LiquidationsDetails />
      )}
      {actives.includes(Drawers.FILTER_DATE) && <DrawerFilterDate />}

      {showDrawerMyAgent && <DrawerMyAgent />}
      {showDrawerSuccess && <DrawerSuccess />}
      {showDrawerError && <DrawerError />}
      {showStatementDrawer && <DrawerStatement />}
      {showDrawerNotification && <DrawerNotifications />}
      {showHelpDrawer.isOpen && <DrawerHelp />}
      {drawerGrains.showDrawerGrains && <DrawerGrains />}
      {showDrawerNotificationDetail && <DrawerNotificationsDetails />}
    </>
  )
}

export default DrawerContainer
