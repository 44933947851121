/* eslint-disable react/require-default-props */
import React from 'react'
import CustomText from '@qirapagos/web/src/components/atoms/CustomText'
import styles, { CursorPointer } from './styles'

interface Props {
  onPress?: () => void
  id?: string
  customStyle?: React.CSSProperties
  disabled?: boolean
  children: string
  isBig?: boolean
  secondary?: boolean
  marginBottom?: number
}

const ButtonLine = ({
  onPress = () => ({}),
  id,
  customStyle = {},
  disabled,
  children,
  isBig = true,
  secondary,
  marginBottom = 0
}: Props) => (
  <button
    onClick={onPress}
    id={id}
    type="button"
    disabled={disabled}
    style={{
      marginBottom
    }}
  >
    <CursorPointer>
      <CustomText
        weight="mediumfont"
        color={secondary ? 'white' : 'purple'}
        size={isBig ? 'xmedium' : 'medium'}
        customStyle={[disabled && styles.disabled, customStyle]}
        underlined
      >
        {children}
      </CustomText>
    </CursorPointer>
  </button>
)

export default ButtonLine
