import {
  IAgent,
  IClientAgent,
  IMultiEnterprise,
  IPaymentMethod,
  IRedeemer,
  ITypePaymentMethod,
  IVendor,
  MenuActionTypes
} from './types'

export const setMenuLoading = () => ({
  type: MenuActionTypes.SET_LOADING
})

export const dismissMenuLoading = () => ({
  type: MenuActionTypes.DISMISS_LOADING
})

export const setVendor = (vendor: IVendor) => ({
  type: MenuActionTypes.SET_VENDOR,
  payload: vendor
})

export const setTypePaymentMethod = (paymentmethod: ITypePaymentMethod[]) => ({
  type: MenuActionTypes.SET_TYPE_PAYMENT_METHOD,
  payload: paymentmethod
})

export const setPaymentMethod = (paymentmethod: IPaymentMethod[]) => ({
  type: MenuActionTypes.SET_PAYMENT_METHOD,
  payload: paymentmethod
})

export const setRedeemers = (redeemers: IRedeemer[]) => ({
  type: MenuActionTypes.SET_REDEEMERS,
  payload: redeemers
})

export const setCountPageRedeemers = (countPages: number) => ({
  type: MenuActionTypes.SET_COUNT_PAGE_REDEEMERS,
  payload: countPages
})

export const setClearRedeemers = () => ({
  type: MenuActionTypes.SET_CLEAR_REDEEMERS
})

export const setClientAgents = (clientAgents: IClientAgent[]) => ({
  type: MenuActionTypes.SET_CLIENT_AGENTS,
  payload: clientAgents
})

export const setAgents = (agents: IAgent) => ({
  type: MenuActionTypes.SET_AGENTS,
  payload: agents
})

export const setMultiEnterpriseAssociations = (assosiations: IMultiEnterprise[]) => ({
  type: MenuActionTypes.SET_MULTIENTERPRISE_ASSOCIATIONS,
  payload: assosiations
})

export const setHasAgents = (hasAgent: boolean) => ({
  type: MenuActionTypes.SET_HAS_AGENT,
  payload: hasAgent
})
