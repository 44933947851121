import React from 'react'
import { DocumentationCard, withFade } from 'components'
import { ViewContainer } from './styles'
import Helmet from 'components/utilities/Helmet'
import { SCREEN_TITLES } from 'utils/navigation'

import useNavigation from 'hooks/useNavigation'
import SignedDocumentsCard from 'components/molecules/SignedDocumentsCard'
import { CompanyStatusLabel, OnboardingStatus, OnboardingStatusLabel, CompanyStatus } from 'utils/common'

const Documentation: React.FC = () => {
  const { onNavigate } = useNavigation()

  const onboardingStatus = OnboardingStatusLabel[Number(localStorage.getItem('onboardingStatus')) as OnboardingStatus]
  const companyStatus = CompanyStatusLabel[Number(localStorage.getItem('companyStatus')) as CompanyStatus]

  const TOOLTIP_MESSAGE =
    'Para más información por favor ir a Atención al Cliente'
  return (
    <ViewContainer>
      <Helmet title={SCREEN_TITLES.DOCUMENTATION} />
      <DocumentationCard
        title="Validación de Identidad"
        description=""
        status={onboardingStatus}
        hasTooltip
        labelTooltip={TOOLTIP_MESSAGE}
        margin={8}
      />
      <DocumentationCard
        title="Documentación de la empresa"
        description=""
        status={companyStatus}
        hasTooltip
        labelTooltip={TOOLTIP_MESSAGE}
        margin={8}
      />
      <SignedDocumentsCard
        title="Documentación"
        description="Firma electrónica"
        onClick={() => onNavigate('/content/documentation/signature')}
      />
    </ViewContainer>
  )
}

export default withFade(Documentation)
