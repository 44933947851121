import * as Sentry from '@sentry/react'
import { ThunkDispatchType } from '@qirapagos/lib/interfaces/reduxInterfaces'
import * as MenuActions from '@qirapagos/lib/store/menu/actions'
import { MultiEnterpriseServices, MenuServices } from 'services'
import { getToken } from 'services/utils'
import { setAgents, setMultiEnterpriseAssociations } from '@qirapagos/lib/store/menu/actions'
import { ErrorMessages } from '@qirapagos/lib/utils/common'
import {
  setShowDrawerError,
  setShowDrawerMyAgent,
  setShowDrawerSuccess
} from '@qirapagos/lib/store/modals/actions'

export const getMultiEnterpriseAssociations =
  () => async (dispatch: ThunkDispatchType) => {
    try {
      dispatch(MenuActions.setMenuLoading())
      const token = await getToken()
      const loginId = await localStorage.getItem('loginId')
      const keyEndpoints = await localStorage.getItem('keyendpoints')
      const response: any = await MultiEnterpriseServices.getAssociations(
        loginId as string,
        token,
        keyEndpoints as string
      )
      Sentry.setExtras({
        data: {
          response: response.data,
          token,
          loginId
        }
      })
      if (response.status === 400) {
        dispatch(setMultiEnterpriseAssociations([]))
        dispatch(MenuActions.dismissMenuLoading())
        return
      }
      dispatch(setMultiEnterpriseAssociations(response.data))
      dispatch(MenuActions.dismissMenuLoading())
    } catch (error) {
      Sentry.captureMessage(
        `Error al conseguir mis asociaciones:${error}`,
        'warning'
      )
      dispatch(setMultiEnterpriseAssociations([]))
      dispatch(MenuActions.dismissMenuLoading())
    }
  }

export const getRedeemers =
    (page = 1, limit = 5) =>
      async (dispatch: ThunkDispatchType) => {
        try {
          dispatch(MenuActions.setMenuLoading())
          const token = await getToken()
          const keyEndpoints = await localStorage.getItem('keyendpoints')
          const response: any = await MenuServices.getRedeemers(
            limit,
            page,
            token as string,
            keyEndpoints as string
          )
          Sentry.setExtras({
            data: {
              response: response.data,
              token
            }
          })
          if (response.status !== 200 && response?.data?.error_message) {
            throw ErrorMessages.DEFAULT_REQUEST_ERROR
          }
          dispatch(MenuActions.setCountPageRedeemers(response.data.countPages))
          dispatch(MenuActions.setRedeemers(response.data.redeemingCompany))
          dispatch(MenuActions.dismissMenuLoading())
        } catch (error) {
          Sentry.captureMessage(
          `Error al conseguir representantes:${error}`,
          'warning'
          )
          dispatch(MenuActions.dismissMenuLoading())
          dispatch(MenuActions.setClearRedeemers())
        }
      }

export const getAgentByCuit =
  (cuit: string, setError: any) => async (dispatch: ThunkDispatchType) => {
    try {
      dispatch(MenuActions.setMenuLoading())
      const token = await getToken()
      const keyEndpoints = await localStorage.getItem('keyendpoints')
      const response: any = await MenuServices.getAgentByCuit(
        cuit,
        token,
        keyEndpoints as string
      )
      if (response.status !== 200 && response?.data?.error_message) {
        throw ErrorMessages.DEFAULT_REQUEST_ERROR
      }
      dispatch(MenuActions.setAgents(response.data))
      dispatch(MenuActions.dismissMenuLoading())
      dispatch(setShowDrawerMyAgent(true))
    } catch (error) {
      dispatch(MenuActions.dismissMenuLoading())
      const errorMessage = error || 'CUIT/CUIL inválido'
      setError('cuit', { type: 'registered', message: errorMessage })
    }
  }

export const newAgent =
  (representativeId: number) => async (dispatch: ThunkDispatchType) => {
    try {
      dispatch(MenuActions.setMenuLoading())
      const title = {
        success: 'Representante agregado',
        error: 'No se pudo agregar el representante seleccionado.'
      }
      const bodyDrawer = {
        success:
          'En las próximas horas se verá reflejada en la cuenta de tu representante.',
        error: ''
      }
      const navigation = {
        path: '/content/agents',
        title: 'Mi representante'
      }
      const token = await getToken()
      const keyEndpoints = await localStorage.getItem('keyendpoints')
      const companyId = await localStorage.getItem('companyId')

      const body = {
        companyId,
        representativeId
      }
      const response: any = await MenuServices.newAgent(
        body,
        token,
        keyEndpoints as string
      )
      Sentry.setExtras({
        data: {
          response: response.data,
          token,
          companyId,
          body
        }
      })
      if (response.status !== 200 && response?.data?.error_message) {
        dispatch(setShowDrawerError(true, title.error, '', navigation))
        throw ErrorMessages.DEFAULT_REQUEST_ERROR
      }

      dispatch(setAgents(response.data))
      // resetFromRoot('ConfirmedNewAgent')

      dispatch(MenuActions.setHasAgents(true))
      dispatch(
        setShowDrawerSuccess(true, true, title.success, bodyDrawer.success, navigation)
      )

      dispatch(MenuActions.dismissMenuLoading())
    } catch (error) {
      Sentry.captureMessage(`Error al crear agente:${error}`, 'error')
      dispatch(MenuActions.dismissMenuLoading())
    }
  }

export const getAgents = () => async (dispatch: ThunkDispatchType) => {
  try {
    dispatch(MenuActions.setMenuLoading())
    const token = await getToken()
    const companyId = await localStorage.getItem('companyId')
    const keyEndpoints = await localStorage.getItem('keyendpoints')
    const response: any = await MenuServices.getAgents(
      token,
      companyId,
      keyEndpoints as string
    )
    if (response.status !== 200) {
      throw new Error(ErrorMessages.DEFAULT_BACKEND_ERROR)
    }
    Sentry.setExtras({
      data: {
        response: response.data,
        token,
        companyId
      }
    })
    if (response.data !== null) {
      dispatch(MenuActions.setHasAgents(true))
      dispatch(MenuActions.setAgents(response.data))
    }
    dispatch(MenuActions.dismissMenuLoading())
  } catch (error) {
    Sentry.captureMessage(
      `Error al conseguir representantes:${error}`,
      'warning'
    )
    dispatch(MenuActions.dismissMenuLoading())
  }
}

export const deleteAgent =
  (id: number) => async (dispatch: ThunkDispatchType) => {
    try {
      dispatch(MenuActions.setMenuLoading())
      const token = await getToken()
      const keyEndpoints = await localStorage.getItem('keyendpoints')
      const response: any = await MenuServices.deleteAgent(
        id,
        token,
        keyEndpoints as string
      )
      Sentry.setExtras({
        data: {
          response: response.data,
          token
        }
      })
      if (response.status !== 200 && response?.data?.error_message) {
        throw ErrorMessages.DEFAULT_REQUEST_ERROR
      }
      // resetFromRoot('ConfirmedDeleteAgent')
      dispatch(MenuActions.setHasAgents(false))
      dispatch(MenuActions.dismissMenuLoading())
    } catch (error) {
      Sentry.captureMessage(
        `Error al eliminar representantes:${error}`,
        'error'
      )
      dispatch(MenuActions.dismissMenuLoading())
    }
  }
