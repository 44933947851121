import React, { useEffect, useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getAccountInfo, setTransferLimit } from 'store/transfers/thunks'
import { useAppSelector } from 'hooks/redux'
import { useForm } from 'react-hook-form'
import useNavigation from 'hooks/useNavigation'
import { setShowDrawerError, setShowDrawerSuccess } from '@qirapagos/lib/store/modals/actions'
import {
  styles,
  Container,
  Title,
  SubTitle,
  FieldContainer,
  Button,
  ButtonContainer,
  CheckboxWithText
} from './styles'
import { Checkbox } from '@chakra-ui/react'
import TransferLimitSkeleton from 'assets/skeletons/TransferLimitSkeleton'
import { FieldCurrency } from 'components/molecules/FieldCurrency'

type FormData = {
  limit: string | null
}

const TransferLimit: React.FC = () => {
  const {
    control, errors, handleSubmit, formState, setError, clearErrors, reset, watch
  } = useForm<FormData>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit'
  })
  const {
    isLoading, isInfoLoading
  } = useAppSelector(state => state.transfers)

  const { account } = useAppSelector(state => state.transfers)
  const [disabledLimit, setDisabledLimit] = useState(false)
  const [checkBox, setCheckbox] = useState(false)
  const dispatch: any = useDispatch()
  const { onNavigate } = useNavigation()
  const limitAmount = watch('limit')

  useEffect(() => {
    dispatch(getAccountInfo()).then((res: any) => {
      setCheckbox(!res.transactionLimit)
    })
  }, [dispatch])

  useEffect(() => {
    if (limitAmount) {
      setCheckbox(false)
    }
  }, [limitAmount])

  const onPressBackToHome = useCallback(() => {
    onNavigate('/content/your-account')
  }, [onNavigate])

  const dispatchTransferLimit = async (limit: number | null) => {
    await dispatch(setTransferLimit(limit))
    dispatch(
      setShowDrawerSuccess(
        true,
        true,
        '¡El límite de transferencia ha sido establecido con éxito!',
        '',
        {
          path: '/content/your-account'
        }
      )
    )
  }

  const onPressSubmit = async (values: FormData) => {
    try {
      if (!values.limit) {
        if (!checkBox) {
          return setError('limit', { message: 'Debe establecer un límite' })
        }
        return await dispatchTransferLimit(null)
      }
      await dispatchTransferLimit(Number(values.limit))
    } catch (error) {
      dispatch(
        setShowDrawerError(
          true,
          'Ocurrió un error al establecer un límite por transferencia. Por favor, volvé a intentralo. ',
          '',
          {
            path: '/content/your-account/limit-transfer'
          }
        )
      )
    }
  }

  if (isInfoLoading) {
    return <TransferLimitSkeleton investmentsFlag={false} />
  }

  return (
    <Container>
      <Title>
        Establecer límite máximo por transferencia
      </Title>
      <SubTitle>
        {account?.transactionLimit
          ? `Tu límite actual es de: $${Number(account?.transactionLimit)?.toFixed(2).replace('.', ',')}`
          : 'No tenes límite definido'}
      </SubTitle>
      <FieldContainer>
        <FieldCurrency
          control={control}
          name="limit"
          placeholder="Límite de transferencia"
          error={errors.limit}
          clearErrors={() => clearErrors('limit')}
          isDisabled={disabledLimit}
          rules={!checkBox
            ? {
                required: 'Campo requerído',
                min: {
                  value: 0.1,
                  message: 'Número inválido'
                }
              }
            : {}}
        />
      </FieldContainer>

      <CheckboxWithText>
        <Checkbox
          size='md'
          isChecked={checkBox}
          colorScheme="purple"
          onChange={(e) => {
            setCheckbox(e.target.checked)
            setDisabledLimit(!checkBox)
            reset({ limit: '' })
          }}
        >
          No establecer ningún límite
        </Checkbox>
      </CheckboxWithText>

      <ButtonContainer>
        <Button
          secondary
          onPress={onPressBackToHome}
          customStyle={styles.buttonGoBack}
          disabled={formState.isSubmitting}
          id="limitTransferGoBack"
        >
          Volver atrás
        </Button>
        <Button
          onPress={handleSubmit(onPressSubmit)}
          customStyle={styles.button}
          disabled={formState.isSubmitting}
          loading={isLoading}
          id="limitTransfer"
        >
          Establecer límite
        </Button>
      </ButtonContainer>
    </Container>
  )
}

export default TransferLimit
