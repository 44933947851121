import React, { useEffect, useRef, useState } from 'react'
import { Field } from 'components/molecules/Field'
import { white } from '@qirapagos/lib/theme/colors'
import { Box } from '@chakra-ui/react'
import Button from '@qirapagos/lib/components/atoms/Button/index.web'
import { useAppSelector } from 'hooks/redux'
import { useDispatch } from 'react-redux'
import { validateAccount } from 'store/transfers/thunks'
import { TSetState } from 'types/generics'

interface Props {
  setIndex: TSetState<number>
  titlesInfo: any
  setError: any
  getValues: any
  errors: any
  control: any
}
export const StepOne: React.FC<Props> = ({
  setIndex,
  titlesInfo,
  setError,
  getValues,
  errors,
  control
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const { account } = useAppSelector(state => state.transfers)

  const dispatch: any = useDispatch()

  const onPress = async (cuit: string) => {
    setIsLoading(true)
    const email = (await localStorage.getItem('email')) || ''
    type TCompare = {
      [key: string]: string
    }
    const toCompare: TCompare = {
      email,
      cvu: account.cvu,
      alias: account.alias
    }

    if (
      Object.keys(toCompare).some(
        key => toCompare[key].toLowerCase() === cuit.toLowerCase()
      )
    ) {
      setError('cuit', {
        message: 'No se puede agendar a usted mismo.'
      })
      setIsLoading(false)
    } else {
      const response = await dispatch(validateAccount(cuit, setError))
      if (!response?.data) {
        setError('cuit', { message: 'No se encuentra la cuenta ingresada' })
      } else {
        titlesInfo.setDataSecondStep(response.data)
        setIndex(1)
        titlesInfo.setComplete(true)
      }
      setIsLoading(false)
    }
  }

  const inputFocusRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    setTimeout(() => {
      if (inputFocusRef.current) {
        inputFocusRef.current.focus()
      }
    }, 0)
  }, [])

  return (
    <Box bg={white} display="flex" flexDirection="column">
      <Box mt={50} mb={50} w="30%" alignSelf="center" minWidth="230px">
        <Field
          control={control}
          name="cuit"
          placeholder="Alias/ CBU/ CVU/ Email"
          error={errors.cuit}
          ref={inputFocusRef}
        />
      </Box>
      <Button
        disabled={false}
        onPress={() => onPress(getValues('cuit'))}
        customStyle={{ width: '15%', alignSelf: 'flex-end', marginRight: 30 }}
        loading={isLoading}
      >
        Continuar
      </Button>
    </Box>
  )
}

export default StepOne
