/* eslint-disable react/require-default-props */
import React, { useEffect, useState, useCallback, useMemo } from 'react'
import ButtonLine from '@qirapagos/lib/components/atoms/ButtonLine'
import TwoFactorAuthenticatorInput from 'components/molecules/TwoFactorAuthenticatorInput'
import { useDispatch, useSelector } from 'react-redux'
import { getMyData, requestTwoFactorCode, verifyTwoFactorCode } from 'store/auth/thunks'
import Button from 'components/atoms/Button'
import { setTwoFactorVerified } from '@qirapagos/lib/store/auth/actions'
import {
  Container,
  FormContainer,
  InputContainer,
  Paragraph,
  PurpleParagraph,
  Title,
  Error,
  CountContainer,
  ButtonsContainer,
  ParagraphContainer,
  ReSendButtonContainer
} from './styles'
import { format2Digits } from '@qirapagos/lib/utils/common'

interface IProps {
  onlyBySMS?: boolean
  onlyByEmail?: boolean
  callback: () => void
  newEmail?: string
  newPhone?: string
}

const TwoFactorAuthentication: React.FC<IProps> = ({
  onlyByEmail,
  onlyBySMS,
  callback,
  newEmail,
  newPhone
}: IProps) => {
  const [hasError, setHasError] = useState(false)
  const [sendByEmail, setSendByEmail] = useState(!!onlyByEmail)
  const [value, setValue] = useState<string>('')
  const [countDownToZero, setCountDownToZero] = useState(false)
  const [timer, setTimer] = useState(false)
  const { mydata: myData } = useSelector((state: any) => state.contracts)
  const { twoFactorVerified, isLoading } = useSelector(
    (state: any) => state.auth
  )

  const [text, setText] = useState('Reenviar código')
  const dispatch = useDispatch()

  useEffect(() => {
    const channelId = onlyByEmail ? 1 : 2 // channel 2 is by SMS
    dispatch(getMyData())
    dispatch(requestTwoFactorCode(channelId, newEmail, newPhone))
  }, [dispatch, onlyByEmail, newEmail, newPhone])

  useEffect(() => {
    setHasError(false)
  }, [value])

  useEffect(() => {
    if (twoFactorVerified) {
      callback()
      dispatch(setTwoFactorVerified(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [twoFactorVerified])

  const onSendBy = useCallback(() => {
    const channelId = onlyByEmail ? 1 : 2 // channel 2 is by SMS
    setSendByEmail(!sendByEmail)
    // sendByEmail is not updated at this point, so the channel id should be the opposite
    dispatch(requestTwoFactorCode(channelId, newEmail, newPhone))
    setTimer(true)
    setCountDownToZero(true)
  }, [dispatch, sendByEmail, newEmail, onlyByEmail, newPhone])

  const onPressReSendCode = useCallback(() => {
    const channelId = onlyByEmail ? 1 : 2 // channel 2 is by SMS
    dispatch(requestTwoFactorCode(channelId, newEmail, newPhone))
    setTimer(true)
    setCountDownToZero(true)
  }, [dispatch, setTimer, newEmail, onlyByEmail, newPhone])

  const onPressSendCode = useCallback(async () => {
    localStorage.setItem('2faCode', value)
    const response = await dispatch(verifyTwoFactorCode(value))
    if (!response) {
      setHasError(true)
    }
  }, [value, dispatch])

  const renderTitle = useMemo(() => {
    let source = 'esta cuenta'
    if (onlyByEmail) {
      source = 'este email'
    } else if (onlyBySMS) {
      source = 'este número de teléfono'
    }
    return `Necesitamos verificar que ${source} te pertenece`
  }, [onlyByEmail, onlyBySMS])

  const renderDestination = useMemo(() => {
    if (newEmail) {
      return newEmail
    } else if (newPhone) {
      return '+' + newPhone
    }
    if (sendByEmail) {
      return myData?.email?.trim()
    } else {
      return myData?.telephoneNumber?.trim()
    }
  }, [myData, newEmail, newPhone, sendByEmail])

  useEffect(() => {
    // eslint-disable-next-line no-undef
    let counter: NodeJS.Timeout
    let seconds = 60
    if (timer) {
      setText('Código reenviado')
      const countDown = () => {
        setCountDownToZero(true)
        counter = setInterval(() => {
          seconds -= 1
          setText(`Volver a intentar en 00:${format2Digits(seconds)}`)
          if (seconds <= 0) {
            setCountDownToZero(false)
            setTimer(false)
            setText('Reenviar código')
          }
        }, 1000)
      }
      setTimeout(countDown, 500)

      return () => {
        if (timer) {
          clearInterval(counter)
        }
      }
    }
  }, [timer])

  return (
    <Container>
      <Title>
        {renderTitle}
      </Title>
      <FormContainer>
        <ParagraphContainer>
          <Paragraph>
            Ingresá el
            <PurpleParagraph> código de 6 dígitos </PurpleParagraph>
            que te
          </Paragraph>
          <Paragraph>
            enviamos a
            <PurpleParagraph>
              {renderDestination}
            </PurpleParagraph>
          </Paragraph>
        </ParagraphContainer>
        <InputContainer>
          <TwoFactorAuthenticatorInput value={value} setValue={setValue} />
        </InputContainer>
        <Error>{hasError && 'El PIN ingresado es incorrecto.'}</Error>
        {!countDownToZero
          ? (
            <ReSendButtonContainer>
              <PurpleParagraph>
                <ButtonLine
                  disabled={isLoading}
                  onPress={onPressReSendCode}
                  isBig
                >
                  Reenviar código
                </ButtonLine>
              </PurpleParagraph>
              {!onlyByEmail && !onlyBySMS && (
                <PurpleParagraph>
                  <ButtonLine disabled={isLoading} onPress={onSendBy} isBig>
                    {sendByEmail ? 'Enviar por teléfono' : 'Enviar por e-mail'}
                  </ButtonLine>
                </PurpleParagraph>
              )}
            </ReSendButtonContainer>
            )
          : (
            <CountContainer>
              <PurpleParagraph>
                <ButtonLine disabled={isLoading} isBig>
                  {text}
                </ButtonLine>
              </PurpleParagraph>
            </CountContainer>
            )}
        <ButtonsContainer>
          <Button
            disabled={isLoading || value.length < 6}
            loading={isLoading}
            onPress={onPressSendCode}
          >
            Verificar
          </Button>
        </ButtonsContainer>
      </FormContainer>
    </Container>
  )
}

export default TwoFactorAuthentication
