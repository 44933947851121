import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getInitials } from '@qirapagos/lib/utils/common'
import { IMultiEnterprise, MultiEnterpriseAssociation } from '@qirapagos/lib/store/menu/types'
import { getMultiEnterpriseAssociations } from 'store/menu/thunks'
import { EnterpriseCard, withFade } from 'components'
import { getStatusTypeString } from 'utils/common'
import { Container } from './styles'
import Helmet from 'components/utilities/Helmet'
import { SCREEN_TITLES } from 'utils/navigation'
import MyEnterprisesSkeleton from 'assets/skeletons/MyEnterprisesSkeleton'

const MyEnterprises: React.FC = () => {
  const dispatch = useDispatch()
  const { multiEnterpriseAssociation, isLoading } = useSelector(
    (state: any) => state.menu
  )

  const sortDataByStatus = (data: IMultiEnterprise[]): IMultiEnterprise[] => {
    const active = data?.filter(
      (item: IMultiEnterprise) => item.companyStatus === 2
    )
    const inactive = data?.filter(
      (item: IMultiEnterprise) => item.companyStatus === 1
    )
    const rejected = data?.filter(
      (item: IMultiEnterprise) => item.companyStatus === 3
    )
    return [...active, ...inactive, ...rejected]
  }

  useEffect(() => {
    dispatch(getMultiEnterpriseAssociations())
  }, [dispatch])

  if (isLoading) return <MyEnterprisesSkeleton />

  return (
    <Container>
      <Helmet title={SCREEN_TITLES.MY_ENTERPRISES} />
      {sortDataByStatus(multiEnterpriseAssociation)?.map((item: MultiEnterpriseAssociation) => (
        <EnterpriseCard
          initials={getInitials(item.businessName)}
          enterpriseName={item.businessName}
          description={item.companyDescription ?? ''}
          status={getStatusTypeString(item.companyStatus ?? 1)}
        />
      ))}
    </Container>
  )
}

export default withFade(MyEnterprises)
