import React, { useMemo } from 'react'
import StageBox from './StageBox'
import { ViewStageContainer } from './styles'
import { StatusType } from './constants'
import { Stages } from 'screens/Home/Grains/ActivityGrains'
import { useAppSelector } from 'hooks/redux'
import { SaleOrderStatus } from 'store/contracts/types'

interface Props {
  currentStage: Stages
}

const Stepper: React.FC<Props> = ({ currentStage }) => {
  const { selected } = useAppSelector(state => state.contractsV2)

  const statusStageTwo = useMemo(() => {
    if (
      selected?.agreement ||
      selected?.status === SaleOrderStatus.REJECTED ||
      selected?.status === SaleOrderStatus.REMOVED
    ) {
      return StatusType.SHEFINISHED
    }
    return StatusType.INPROGRESS
  }, [selected])

  const statusStageThree = useMemo(() => {
    if (selected?.agreement && !selected?.applications?.length) {
      return StatusType.INPROGRESS
    } else if (selected?.applications?.length) {
      return StatusType.SHEFINISHED
    }
    return StatusType.PENDING
  }, [selected])

  const statusStageFour = useMemo(() => {
    if (selected?.applications?.length && !selected.liquidations?.length) {
      return StatusType.INPROGRESS
    } else if (selected?.liquidations?.length) {
      return StatusType.SHEFINISHED
    }
    return StatusType.PENDING
  }, [selected])

  return (
    <ViewStageContainer>
      <StageBox
        title="Etapa 1"
        text="Inicio de venta"
        statusType={StatusType.SHEFINISHED}
        isSelected={currentStage === Stages.INIT}
      />
      <StageBox
        title="Etapa 2"
        text="Confirmación de contrato"
        statusType={statusStageTwo}
        isSelected={currentStage === Stages.CONFIRMATION}
      />
      <StageBox
        title="Etapa 3"
        text="Entrega de granos"
        statusType={statusStageThree}
        isSelected={currentStage === Stages.QUOTES}
      />
      <StageBox
        title="Etapa 4"
        text="Aplicaciones de entregas de contratos"
        statusType={statusStageFour}
        isSelected={currentStage === Stages.APPLICATIONS}
      />
      <StageBox
        title="Etapa 5"
        text="Liquidaciones"
        statusType={
          selected?.liquidations?.length
            ? StatusType.SHEFINISHED
            : StatusType.PENDING
        }
        isSelected={currentStage === Stages.LIQUIDATIONS}
      />
    </ViewStageContainer>
  )
}

export default Stepper
